<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="notenBerechnen">
                    {{ $t("global.calculategrades") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="notenUebersicht">
                    {{ $t("global.gradeoverview") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="setCancelled">
                    {{ $t("global.cancelevent") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.b_bildung_klasse.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <klasse-stammdaten
            :klasse="klasse"
            :editable="editable"
            :isTemplate="false"
            @eventCancelled="openCancellationModal"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <klasse-bildungsgang
            :v-if="klassenid"
            :klasse="klasse"
            :editable="editable"
            :isTemplate="false"
          />

          <klasse-teilnehmer
            :v-if="klassenid"
            :klasse="klasse"
            :editable="editable"
            :isTemplate="false"
          />

          <!--    <klasse-lektionen
            :v-if="klassenid"
            :klasse="klasse"
            :editable="editable"
            :isTemplate="false"
          /> -->
        </div>

        <!-- ENDE Rechte Seite -->
        <div v-if="klassenid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 0 },
                    }"
                    >{{ $t("global.registration") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="berechtigungen.b_bildung_fach.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 1 },
                    }"
                    >{{ $t("global.dashboard_subjects") }}</router-link
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 2 },
                    }"
                    >{{ $t("global.scoreinput") }}</router-link
                  >
                </li>
                <!--<li
                  class="nav-item"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    v-if="berechtigungen.b_bildung_notenpromotion.read"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 3 },
                    }"
                    >Promotion</router-link
                  >
                </li>-->
                <li class="nav-item" v-if="berechtigungen.m_finanzen.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 4 },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 5 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 5 },
                    }"
                    >{{
                      $t("global.additionalregistrationinformation")
                    }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 6 }"
                    :to="{
                      name: 'klasse',
                      params: { klassenid: klassenid, anzeige: 6 },
                    }"
                    >{{ $t("global.survey") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <anmeldungsliste
                    :veranstaltung="klassenid"
                    :klassenkuerzel="klasse.kuerzel"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :key="anmeldungslisteKey"
                    :lehrgang="klasse.lehrgang"
                    :semester="klasse.periodennummer"
                  />
                </div>
                <div
                  v-show="anzeige === 1"
                  v-if="berechtigungen.b_bildung_fach.read"
                >
                  <fachliste
                    :veranstaltung="klassenid"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                  />
                </div>

                <div
                  v-show="anzeige === 2"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <noteneingabeliste
                    :veranstaltung="klassenid"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :klasseObject="klasse"
                  />
                </div>

                <!--<div
                  v-show="anzeige === 3"
                  v-if="berechtigungen.b_bildung_noteneingabe.read"
                >
                  <notenpromotionliste
                    v-if="berechtigungen.b_bildung_notenpromotion.read"
                    :veranstaltung="klassenid"
                    :shown="anzeige == 3 ? true : false"
                  />
                </div>-->
                <div
                  v-show="anzeige === 4"
                  v-if="berechtigungen.m_finanzen.read"
                >
                  <verrechnungspositionen
                    :editable="editable"
                    :veranstaltung="klassenid"
                    :shown="anzeige == 4 ? true : false"
                    :last-abacus-export="klasse.last_abacus_export"
                    :kuerzel="klasse.kuerzel"
                    :isVeranstaltung="true"
                  />
                </div>
                <div v-show="anzeige === 5">
                  <zusatzangebote
                    :klassenid="klassenid"
                    :lehrgangid="klasse.lehrgang.id ? klasse.lehrgang.id : null"
                    :shown="anzeige == 5 ? true : false"
                    :editable="editable"
                    :is-short-event="false"
                  />
                </div>
                <!-- Start Umfrage -->
                <div v-if="anzeige === 6">
                  <umfrage-reiter
                    :veranstaltung="klasse"
                    :veranstaltungId="klassenid"
                    :shown="anzeige == 6 ? true : false"
                    :lehrgang="klasse.lehrgang ? klasse.lehrgang : null"
                    :editable="editable"
                  />
                </div>
                <!-- Ende Umfrage -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <messagebox-enhanced
      :headerText="$t('global.startpromotion')"
      :ok="true"
      @ok="promotionStart"
    />

    <messagebox-enhanced
      id="event-cancellation-modal"
      :headerText="$t('global.cancelevent')"
      :ok="true"
      @ok="cancelEvents"
      :okText="$t('global.cancelevent')"
      @cancel="setPreviousEventState"
    >
      <font-awesome-icon
        v-tooltip.hover
        :title="$t('global.eventcancellationtooltip')"
        icon="fa-solid fa-circle-info"
      />
      <div v-for="(option, index) in cancellationOptions" :key="index">
        <label>
          <input
            type="radio"
            :value="option.value"
            v-model="selectedCancellationOption"
          />
          {{ option.text }}
        </label>
      </div>
    </messagebox-enhanced>

    <loeschen-modal id="klasse-loeschen-modal" @confirmed="klasseloeschen" />
  </div>
</template>

<script>
import Api from "@/Api";
import App from "@/AppApi";
import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import KlasseStammdaten from "@/components/Bildung/Klasse/Stammdaten";
import KlasseBildungsgang from "@/components/Bildung/Klasse/Bildungsgang";
import KlasseTeilnehmer from "@/components/Bildung/Klasse/Teilnehmer";

import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import Fachliste from "@/components/Reiter/Fachliste";
import Noteneingabeliste from "@/components/Reiter/Noteneingabeliste";
import Verrechnungspositionen from "@/components/Reiter/Verrechnungspositionen.vue";
import Zusatzangebote from "@/components/Reiter/Zusatzangebote";
import UmfrageReiter from "@/components/Reiter/Questionaireliste";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "Klasse",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    KlasseStammdaten,
    KlasseBildungsgang,
    KlasseTeilnehmer,
    UnsavedChangesModal,
    Anmeldungsliste,
    Noteneingabeliste,
    Fachliste,
    MessageboxEnhanced,
    Verrechnungspositionen,
    Zusatzangebote,
    UmfrageReiter,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.class");
      },
    };
  },
  mixins: [page, ChangeWatcherNew],

  props: {
    klassenid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      anmeldungslisteKey: 0,
      klasse: {
        lehrgang: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
        },
        verantwortlicher: { personName: this.$t("global.notlinked") },
      },
      cancellationOptions: [
        { value: "currentEvent", text: this.$t("global.onlytheseevents") },
        {
          value: "currentAndSubevents",
          text: this.$t("global.theseeventsandsubevents"),
        },
        {
          value: "allEvents",
          text: this.$t("global.alleventsfromsamecourse"),
        },
      ],
      selectedCancellationOption: null,
      previousEventState: null,
    };
  },
  computed: {
    navbarTitel() {
      if (this.klasse.bezeichnung !== "Neue Veranstaltung")
        return this.$t("global.class") + ": " + this.klasse.bezeichnung;
      else return this.$t("global.newclass");
    },
  },
  watch: {
    klassenid: {
      handler: function (val) {
        if (val) {
          this.ladeVeranstaltung(val);
        } else {
          this.editable = this.berechtigungen.b_bildung_klasse.update;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted: function () {},
  methods: {
    ladeVeranstaltung(klassenid) {
      this.loading = true;
      Api.get("veranstaltungen/", { params: { id: klassenid } })
        .then((response) => {
          /*
          if (response.data.error.length > 0) {
            this.$router.push({ name: "klassen-liste" });
          } else {
            this.initializeKlasse(response.data);
          }
          */
          this.initializeKlasse(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initializeKlasse(klasse) {
      if (klasse) {
        this.klasse = klasse;

        if (!this.klasse.verantwortlicher)
          this.klasse.verantwortlicher = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.klasse.klassenchef)
          this.klasse.klassenchef = { personName: this.$t("global.notlinked") };

        if (!this.klasse.stvklassenchef)
          this.klasse.stvklassenchef = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.klasse.lehrgang)
          this.klasse.lehrgang = {
            bezeichnung: this.$t("global.notlinked"),
            lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
          };

        if (!this.klasse.lehrgang.lehrgangstatus)
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.klasse.lehrgang.lehrgangstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.previousEventState = this.klasse.veranstaltungsstatus;

        this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
          this.klasse.veranstaltungsstatus.id
        )
          ? true
          : false;

        if (!this.berechtigungen.b_bildung_klasse.update) this.editable = false;

        //this.initializeChangewatcher(["klasse"]);
      } else {
        console.error("Klasse ist null");
      }

      //this.initializeChangewatcher(["klasse"]);
      this.editable = this.berechtigungen.b_bildung_klasse.update;
    },

    promotion() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("global.shouldthepromotionbestarted"),
      });
    },

    promotionStart() {
      Api.put(
        "noten/promotion",
        {},
        {
          params: { klasse: this.klasse.id },
        }
      ).then(() => {});
    },
    /*   notenBerechnen() {
      this.loading = true;

      Api.post(
        "noten/promotion/",
        {},
        {
          params: { semesterid: this.klassenid },
        }
      )
        .then(() => {})
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.gradecalculationperformed"),
          });
        });
    }, */
    notenBerechnen() {
      this.loading = true;

      App.post(
        "veranstaltung/notenberechnung/",
        {},
        {
          params: { id: this.klassenid },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.gradecalculationperformed"),
            });
          }
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
          // this.$notify({
          //   type: "success",
          //   title: this.$t("global.actionsuccessful"),
          //   text: this.$t("global.gradecalculationperformed"),
          // });
        });
    },
    notenUebersicht() {
      this.$router.push({
        name: "Notenuebersicht",
        params: {
          klassenid: this.klasse.id,
        },
      });
    },
    abbrechen() {
      this.$router.push({ name: "klassen-liste" });
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;

      var json = Object.assign({}, this.klasse);

      json.qualifikationen = this.klasse.qualifikationen.map((qa) => {
        return qa.id;
      });

      json.themen = this.klasse.themen.map((t) => {
        return t.id;
      });

      json.lehrgang = this.klasse.lehrgang?.id;
      json.veranstaltungsstatus = this.klasse.veranstaltungsstatus?.id;
      json.veranstaltungstyp = this.klasse.veranstaltungstyp?.id;
      json.verantwortlicher = this.klasse.verantwortlicher?.id;
      json.periode = this.klasse.periode?.id;

      Api.put("veranstaltungen/", json, { params: { id: this.klasse.id } })
        .then((response) => {
          this.initializeKlasse(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.classsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "klasse-loeschen-modal");
    },
    anmeldungslisteUpdate() {
      this.anmeldungslisteKey++;
    },
    klasseloeschen() {
      Api.delete("veranstaltungen/", {
        params: { id: this.klassenid },
      }).then(() => {
        this.$router.push({ name: "klassen-liste" });
      });
    },
    openCancellationModal(newState, oldState) {
      this.previousEventState = oldState;
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "event-cancellation-modal",
        text: this.$t("global.selectwhicheventsshouldbecancelled"),
      });
    },
    cancelEvents() {
      this.loading = true;
      Api.put(
        "veranstaltungen/cancel/",
        {},
        {
          params: {
            id: this.klasse.id,
            cancellationOption: this.selectedCancellationOption,
          },
        }
      )
        .then((response) => {
          this.initializeKlasse(response.data);
          this.anmeldungslisteUpdate();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.classsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPreviousEventState() {
      if (this.previousEventState)
        this.klasse.veranstaltungsstatus = this.previousEventState;
    },
    setCancelled() {
      this.klasse.veranstaltungsstatus = { id: "174D9067E04" };
    },
  },
};
</script>

<style></style>
