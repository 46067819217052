<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <tor-abschluss-waehlen-modal
      :id="'tor-abschluss-waehlen-modal'"
      :abschluesse="degrees"
      @setAbschluss="setAbschlussSimulation"
    />
    <tor-abschluss-simulieren-modal
      :id="'tor-abschluss-simulieren-modal'"
      :abschluss="abschlussSimulation"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

import Tor from "@/Tor";
import store from "@/store";
import page from "@/mixins/Page";
import torDashboard from "@/mixins/TorDashboard";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import TorAbschlussWaehlenModal from "@/components/Tor/TorAbschlussWaehlenModal";
import TorAbschlussSimulierenModal from "@/components/Tor/TorAbschlussSimulierenModal";

export default {
  name: "TorAbschlussSimulieren",
  components: {
    Navbar,
    TorAbschlussWaehlenModal,
    TorAbschlussSimulierenModal,
  },
  mixins: [page, torDashboard],
  props: {},
  store,
  data() {
    return {
      degrees: [],
      abschlussSimulation: {},
      navbarTitel: "ToR " + this.$t("global.simulatedegrees"),
    };
  },
  watch: {},
  created() {
    this.initFilter("tor-liste", "students/", true);
    this.holeAuswahlwerte();
  },
  mounted() {
    this.oeffneAbschlussWaehlenModal();
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      //"",
      "number",
      "surname",
      "firstname",
    ]);
  },
  methods: {
    holeAuswahlwerte() {
      //Abschluesse
      Tor.get("degrees/", {})
        .then((response) => {
          this.degrees = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Perioden
      Tor.get("periods/", {})
        .then((response) => {
          this.periods = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Veranstaltungen
      Tor.get("events/", {})
        .then((response) => {
          this.events = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },
    refresh() {
      this.resetLoading(this);
    },
    details(geschaeftspartner) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: geschaeftspartner.id, anzeige: 17 },
      });
    },

    setFilterPerson(student) {
      this.$set(this.filters, "student", student);
    },
    oeffneAbschlussWaehlenModal() {
      this.$bus.$emit("openAbschlussWaehlenModal", {
        modalId: "tor-abschluss-waehlen-modal",
      });
    },
    oeffneAbschlussSimulierenModal() {
      this.$bus.$emit("openAbschlussSimulierenModal", {
        modalId: "tor-abschluss-simulieren-modal",
      });
    },
    notenUebernehmen() {
      this.loading = true;

      Tor.post("grades/", {})
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: "Noten erfolgreich übernommen.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setAbschlussSimulation(abschluss) {
      this.abschlussSimulation = abschluss;
      this.oeffneAbschlussSimulierenModal();
    },
  },
};
</script>

<style></style>
