<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.filterKurzbezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterBezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.filterLehrgangstatus"
                        :options="lehrgangstati"
                        :reduce="(lsl) => lsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statuseducationalcourse')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatuseducationcoursefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.verantwortlicher"
                      :label="$t('global.responsible')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :gesamtanzahl="gesamtanzahl"
              :anzahl="anzahlDaten"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4 row">
                    <router-link
                      v-if="berechtigungen.b_bildung_bildungsgang.create"
                      :to="{
                        name: 'template-bildungsgang',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.newtemplate") }}</router-link
                    >
                    <massenmutation-button
                      :selectedIds="this.selectedIds"
                      :propEntities="propEntities"
                      @mutated="refresh"
                    />
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      v-if="berechtigungen.b_bildung_bildungsgang.delete"
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="bildungsgangloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
//import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import filter from "@/mixins/Filter";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import InfiniteLoading from "vue-infinite-loading";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "template-bildungsgang-liste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectPerson,
    FilterSearchResetButton,
    MassenmutationButton,
  },
  mixins: [page, filter],
  props: {},
  store,
  data() {
    return {
      //veranstaltungen: [],

      //filterBezeichnung: this.$route.query.filterBezeichnung,
      //filterKurzbezeichnung: this.$route.query.filterKurzbezeichnung,
      //filterLehrgangstatus: this.$CONST("LEHRGANGSTATI").PLANNED,
      //filterCount: null,
      //verantwortlicher: this.$route.query.verantwortlicher,
      //filterDozent: { personName: this.$t("global.notlinked") },
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,

      infiniteId: +new Date(),
      sortBy: "bezeichnung",
      sortDesc: false,

      selected: [],
      selectedIds: [],
      propEntities: [
        {
          tbl_name: "bxc_templatelehrgang",
          selectedIds: [],
        },
        {
          tbl_name: "bxc_portalinfo",
          selectedIds: [],
        },
      ],
      navbarTitel: this.$t("global.templateseducationalcourses"),
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "lehrgangleiter.personName",
          sortable: true,
          label: this.$t("global.responsible"),
        },
        {
          key: "lehrgangstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.setQueryParam(
        this.filters.filterBezeichnung
      );
      params.filterKurzbezeichnung = this.setQueryParam(
        this.filters.filterKurzbezeichnung
      );
      params.filterLehrgangstatus = this.setQueryParam(
        this.filters.filterLehrgangstatus
      );
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher?.id
      );

      //if (this.filters.filterDozent.id) params.dozent = this.setQueryParam(this.filters.filterDozent.id);

      if (this.filters.filterCount != "")
        params.filterCount = this.setQueryParam(this.filters.filterCount);

      return params;
    },
    lehrgangstati: {
      get() {
        return this.$store.state.veranstaltung.lehrgangstati;
      },
    },
  },
  watch: {
    selectedIds(newVal) {
      // set Portalinfo IDs for selected courses (used for massmutation)
      if (newVal.length == 0) {
        this.propEntities[1].selectedIds = [];
        return;
      }
      const selectedPortalinfoIds = [];
      newVal.forEach((id) => {
        const correspondingPortalinfoId = this.listData.find(
          (el) => el.id == id
        )?.portalinfoId;
        if (correspondingPortalinfoId) {
          selectedPortalinfoIds.push(correspondingPortalinfoId);
        }
      });

      this.propEntities[1].selectedIds = selectedPortalinfoIds;
    },
    /* filterBezeichnung: function () {
      this.resetLoading(this);
    },
    filterKurzbezeichnung: function () {
      this.resetLoading(this);
    },
    filterLehrgangstatus: function () {
      this.resetLoading(this);
    },
    verantwortlicher: function () {
      this.resetLoading(this);
    },
    filterDozent: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    }, */
  },
  created() {},
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.initFilter("template-bildungsgang-liste", "template/lehrgang/", true);

    if (this.lehrgangstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    let state = this.$store.state.dashboard.list["template-bildungs-liste"];

    if (state) {
      this.filters.filterBezeichnung = state.bezeichnung;
      this.filters.filterKurzbezeichnung = state.kurzbezeichnung;
      this.filters.filterLehrgangstatus = state.lehrgangstatus;
      /*     ? state.lehrgangstatus
        : this.$CONST("LEHRGANGSTATI").PLANNED; */
      this.verantwortlicher = state.verantwortlicher;
    } /* else {
      this.filters.filterLehrgangstatus = this.$CONST("LEHRGANGSTATI").PLANNED;
    } */
  },
  methods: {
    details(bildungsgang) {
      this.storeFilter();

      this.$router.push({
        name: "template-bildungsgang",
        params: { lehrgangid: bildungsgang.id, anzeige: 0 },
      });
    },

    setFilterDozent(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    /* resetLoading: debounce((self) => {
      self.page = 0;
      self.veranstaltungen = [];
      self.infiniteId += 1;
    }, 500), */

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: "Kein Bildungsgang ausgewählt.",
        });
      }
    },

    bildungsgangloeschen() {
      Api.delete("template/lehrgang/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: "Vorlage Bildungsgang gelöscht",
      });
    },

    storeFilter() {
      this.$store.dispatch(`dashboard/${SET_LISTEN_FILTER}`, {
        list: "template-bildungs-liste",
        state: {
          bezeichnung: this.filters.filterBezeichnung,
          kurzbezeichnung: this.filters.filterKurzbezeichnung,
          lehrgangstatus: this.filters.filterLehrgangstatus,
          verantwortlicher: this.filters.verantwortlicher,
        },
      });
    },

    /* infiniteHandler($state) {
      let state1 = false;
      let state2 = false;

      Api.get("/template/lehrgang/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.veranstaltungen.push(...response.data);
          $state.loaded();
        } else {
          this.veranstaltungen.push(...response.data);
          state2 = true;
          if (state1 && state2) {
            $state.complete();
          }
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    }, */
  },
};
</script>

<style></style>
