<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark d-flex"
      :style="systemColor()"
    >
      <div class="primary-headline-text text-white pt-1">{{ headline }}</div>
      <div
        v-if="showIcons"
        class="ml-auto text-white d-flex align-items-center"
      >
        <font-awesome-icon
          icon="fa-regular fa-magnifying-glass"
          :class="disabled ? 'p-1 mr-2' : 'p-1 clickable mr-2'"
          @click="disabled ? '' : search()"
          :title="disabled ? $t('global.pleasechangethetab') : ''"
          size="xs"
        />
        <font-awesome-icon
          icon="fa-light fa-xmark"
          :class="disabled ? 'p-1' : 'p-1 clickable'"
          @click="disabled ? '' : reset()"
          :title="disabled ? $t('global.pleasechangethetab') : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";

export default {
  name: "HeadMenuFilter",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
    showIcons: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  methods: {
    systemColor() {
      switch (this.$CONST("CONFIG").ENVIRONMENT) {
        case "DEV":
          return "background-color: #5F379B";
        case "TEST":
          return "background-color: #248C8C";
        default:
          return "background-color: #124862";
      }
    },

    search() {
      this.$bus.$emit("searchWithFilter");
    },
    reset() {
      this.$bus.$emit("resetFilter");
    },
  },
};
</script>

<style></style>
