<template>
  <messagebox-enhanced
    :headerText="$t('global.saverecord')"
    id="change-watcher-alert-msgbox"
    :ok="true"
    :cancel="true"
    :okText="$t('global.discard')"
    :cancelText="$t('global.cancel')"
    @ok="performIgnore"
  />
  <!-- 
  TODO BXEDUWEI-189
   Options in modal:
   - save -> perform save method from current page
   - discard changes and move on -> no save, move on to next route
   - cancel / close modal -> stay on site and edit more
   -->
</template>

<script>
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "ChangeWatcherAlert",
  components: { MessageboxEnhanced },

  props: {}, // TODO BXEDUWEI-189 speichern method übergeben ?
  data() {
    return {
      nextFunc: null,
    };
  },
  watch: {},
  mounted: function () {
    this.$bus.$on("saved-needed", (next) => {
      this.nextFunc = next;
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "change-watcher-alert-msgbox",
        text: this.$t("global.unsavedchangesdoyouwanttodiscard"),
      });
    });
  },
  methods: {
    performIgnore() {
      this.nextFunc();
    },
  },
};
</script>
