<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row pt-4 pb-3">
                  <div class="col-12">
                    <div class="col-xl-12"></div>
                  </div>
                </div>

                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :gesamtanzahl="gesamtanzahl"
              :anzahl="anzahlDaten"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2"></div>
                  <div class="mr-2"></div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <div slot="spinner">{{ $t("global.loadmorecompetencygrids") }}</div>
      <div slot="no-more">{{ $t("global.nofurthercompetencegrids") }}</div>
      <div slot="no-results">
        {{ $t("global.nofurthercompetencegridsfound") }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";

import Navbar from "@/components/Navbar";
import InfiniteLoading from "vue-infinite-loading";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

export default {
  name: "Kompetenzrasterliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    InfiniteLoading,
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 50,

      listData: [],

      sortBy: "bezeichnung",
      sortDesc: true,
      infiniteId: +new Date(),
    };
  },
  computed: {
    navbarTitel() {
      return this.$t("global.settingcompetencegrid");
    },
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", ["", "designation"]);
  },
  created() {
    this.initFilter("kompetenzraster-liste", "bildung/kompetenzraster/", true);
  },
  methods: {
    details(raster) {
      this.$router.push({
        name: "einstellungen-kompetenzeinstellungen-crmraster",
        params: { id: raster.id, anzeige: 0 },
      });
    },
  },
};
</script>

<style></style>
