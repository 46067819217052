<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.dashboard_presence')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-primary"
              @click="
                $bus.$emit(
                  'open-modal',
                  'modulbestaetigung-fuer-selektierte-anzeigen-modal'
                )
              "
              :disabled="selectedAnmeldungIds.length === 0"
              :title="
                selectedAnmeldungIds.length === 0
                  ? $t('global.selectminone')
                  : ''
              "
            >
              <span>{{ $t("global.viewmoduleconfirmation") }}</span>
            </button>
            <button
              class="btn btn-primary ml-2"
              @click="
                $bus.$emit(
                  'open-modal',
                  'modulbestaetigung-fuer-selektierte-zuweisen-modal'
                )
              "
              :disabled="selectedAnmeldungIds.length === 0"
              :title="
                selectedAnmeldungIds.length === 0
                  ? $t('global.selectminone')
                  : ''
              "
            >
              <span>{{ $t("global.assignmoduleconfirmation") }}</span>
            </button>
          </div>

          <div class="mr-4">
            <button
              class="btn btn-primary mr-2"
              @click="veranstaltungsuebergreifendeAnwesenheitsuebersicht"
            >
              <span>{{ $t("global.crosseventattendanceoverview") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="praesenzen.gesamtanwesenheiten"
                :fields="fields"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                thead-tr-class="pointer"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(datum)="row">
                  {{
                    row.item.anmeldedatum
                      ? row.item.anmeldedatum +
                        (row.item.abmeldedatum
                          ? " - " + row.item.abmeldedatum
                          : "")
                      : "-"
                  }}
                </template>

                <template #cell(details)="row">
                  <b-button
                    @click="row.toggleDetails"
                    class="m-0"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon
                      :icon="
                        row.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'
                      "
                    />
                  </b-button>
                </template>
                <template #row-details="row">
                  <div class="mb-2">
                    <b-table
                      thead-class="z"
                      tbody-tr-class="item"
                      small
                      responsive
                      sticky-header
                      :items="getAnwesenheiten(row.item.anmeldungs_id)"
                      :fields="fieldsDetails"
                      :busy="isBusy"
                      show-empty
                      sort-icon-left
                      selectable
                      select-mode="range"
                      thead-tr-class="pointer no-bg"
                      striped
                    >
                      <template v-slot:cell(termin)="row">
                        {{
                          row.item.termin_von
                            ? row.item.termin_von +
                              (row.item.termin_bis
                                ? " - " + row.item.termin_bis
                                : "")
                            : "-"
                        }}
                      </template>

                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle"></b-spinner>
                        </div>
                      </template>

                      <template #empty>
                        <div
                          v-if="ladeFehler"
                          class="text-center text-danger my-2"
                        >
                          <strong>{{ $t("global.errorwhileloading") }}</strong>
                        </div>
                        <div
                          v-if="!ladeFehler"
                          class="text-center text-primary my-2"
                        >
                          <strong>{{ $t("global.nodataavailable") }}</strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-fuer-selektierte-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      @ok="showPDFForSelected"
    />
    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-fuer-selektierte-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      @ok="assignPDFForSelected"
    />
    <pdf-layer
      id="pdf-layer-multi"
      ref="pdfLayerMulti"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    />
  </div>
</template>

<script>
import AppApi from "@/AppApi";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";
import ZertifikatAuswahlModal from "@/components/Modals/ZertifikatAuswahlModal";
import Api from "@/Api";
import server from "@/server";

export default {
  name: "Anwesenheit",
  components: {
    ContainerHeadline,
    BSpinner,
    ZertifikatAuswahlModal,
  },
  mixins: [reiter],
  props: {
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    kurzveranstaltungid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      praesenzen: {},
      selectedAnmeldungIds: [],
      selectedObjects: [],
      selectAll: null,
      pdfPath: "",
      pdfFiles: [],
      assignCertificateError: [],
      assignCertificateSuccess: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.praesenzen.length == 0);
    },
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "person_name",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "anmeldestatus",
          sortable: false,
          label: this.$t("global.Registrationstatus"),
        },
        {
          key: "datum",
          sortable: true,
          label: this.$t("global.registrationderegistrationdate"),
        },
        {
          key: "anwesenheitsrate",
          sortable: true,
          label: this.$t("global.attendance"),
        },
        {
          key: "bemerkung",
          sortable: false,
          label: this.$t("global.comment"),
        },
        {
          key: "details",
          sortable: false,
          label: "",
        },
      ];
    },
    fieldsDetails() {
      return [
        {
          key: "praesenzstatus",
          sortable: false,
          label: this.$t("global.presencestatus"),
        },
        {
          key: "anwesenheitsrate",
          sortable: false,
          label: this.$t("global.attendance"),
        },
        {
          key: "termin",
          sortable: true,
          label: this.$t("global.appointment"),
        },
        {
          key: "fachkompetenzen",
          sortable: false,
          label: this.$t("global.subjectprofessionalcompetencies"),
          formatter: (value) => {
            if (value) {
              return value;
            } else {
              return "-";
            }
          },
        },
        {
          key: "sozialverhalten",
          sortable: false,
          label: this.$t("global.worksocialbehavior"),
          formatter: (value) => {
            if (value) {
              return value;
            } else {
              return "-";
            }
          },
        },
      ];
    },
  },
  mounted() {
    if (this.shown && this.praesenzen.length == 0);
  },
  created() {
    if (this.kurzveranstaltungid != null) {
      AppApi.get("anwesenheiten/uebersicht/", {
        params: { veranstaltungsId: this.kurzveranstaltungid },
      })
        .then((response) => {
          this.praesenzen = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    getAnwesenheiten(id) {
      return this.praesenzen.anwesenheiten.filter(
        (anwesenheiten) => anwesenheiten.anmeldungs_id === id
      );
    },

    veranstaltungsuebergreifendeAnwesenheitsuebersicht() {
      this.$router.push({
        name: "VeranstaltungsuebergreifendeAnwesenheitsuebersicht",
        params: {
          kurzveranstaltungid: this.kurzveranstaltungid,
        },
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", true);
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedAnmeldungIds = this.$refs.selectableTable.items.map(
          (item) => item.anmeldungs_id
        );
      } else {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", false);
        });
        this.selectedAnmeldungIds = [];
        this.selectedObjects = [];
      }

      this.selectAll = Date.now();
    },

    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }
      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedAnmeldungIds = this.selectedObjects.map(
        (item) => item.anmeldungs_id
      );
    },

    // Kursbestätigung PDFs

    async showPDFForSelected(json) {
      //Sortiert das array nach Name, Vorname
      this.selectedObjects.sort((a, b) => {
        const aNames = a.person_name.split(" ");
        const bNames = b.person_name.split(" ");

        const lastNameSort = aNames[0].localeCompare(bNames[0]);
        if (lastNameSort !== 0) {
          return lastNameSort;
        }

        return aNames[aNames.length - 1].localeCompare(
          bNames[bNames.length - 1]
        );
      });

      await this.selectedObjects.forEach((person, index) => {
        //Generiere das PDF für die ausgewählten Personen und füge es dem PDF-Layer hinzu.
        this.generatePDFforSelectedView(person, index, json);
      });
      //Zeige den PDF-Layer mit den hinzugefügten Files, sobald alle PDFs generiert wurden.
      this.$refs.pdfLayerMulti.show();
    },
    generatePDFforSelectedView(person, index, certificateData) {
      this.pdfFiles = [];
      let kursname = "";
      if (certificateData.certificateType?.bezeichnung) {
        kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
          " ",
          "_"
        );
      }
      let date = certificateData.date;
      let language = certificateData.language;
      let pdfAnzeigename = person.person_name;

      let reportName = "";
      let certificateName = "";
      let pdfPath = "";

      let fileName = "";
      reportName = "modulbestaetigung-" + language;

      switch (language) {
        case "FR":
          certificateName = "Attestation";
          break;
        case "IT":
          certificateName = "Attestazione";
          break;
        case "EN":
          certificateName = "Attestation";
          break;
        default:
          certificateName = "Kursbestaetigung";
      }

      fileName =
        certificateName +
        kursname +
        "_" +
        person.person_name.replaceAll(" ", "_");

      pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${person.person_id}&date=${date}`;

      Api.get(pdfPath).then((response) => {
        let retValue = {
          index: index,
          url: server.url + response.data.filepath,
          withCredentials: true,
          count: this.selectedAnmeldungIds.length,
          size: pdfAnzeigename, //Variable wurde im Backend 'size' benannt, wird aber für den Anzeigenamen im PDF-Viewer genutzt
        };

        this.pdfFiles.push(retValue);
      });
    },
    async assignPDFForSelected(json) {
      this.loading = true;

      await this.selectedObjects.forEach((person) => {
        //Generiere das PDF für die ausgewählten Personen und verknüpfe es mit der Person.
        this.generatePDFforSelectedAssign(person, json);
      });

      this.loading = false;

      //Zeige Erfolgs- oder Fehlermeldungen
      if (this.assignCertificateSuccess.length > 0) {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text:
            this.$t("global.assigncertificatessuccess") +
            `: (${this.assignCertificateSuccess.length})`,
        });
      }
      if (this.assignCertificateError.length > 0) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text:
            this.$t("global.assigncertificateserror") +
            `: ${this.assignCertificateError.toString()}`,
        });
      }
    },

    generatePDFforSelectedAssign(person, certificateData) {
      this.pdfFiles = [];
      let kursname = "";
      if (certificateData.certificateType?.bezeichnung) {
        kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
          " ",
          "_"
        );
      }
      let date = certificateData.date;
      let language = certificateData.language;

      let reportName = "";
      let certificateName = "";
      let pdfPath = "";
      let fileName = "";
      reportName = "modulbestaetigung-" + language;

      switch (language) {
        case "FR":
          certificateName = "Attestation";
          break;
        case "IT":
          certificateName = "Attestazione";
          break;
        case "EN":
          certificateName = "Attestation";
          break;
        default:
          certificateName = "Kursbestaetigung";
      }

      fileName =
        certificateName +
        kursname +
        "_" +
        person.person_name.replaceAll(" ", "_");

      pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${person.person_id}&date=${date}`;

      let error = false;
      //Speichere das PDF als Dokument und verknüpfe es mit der entsprechenden Person.
      Api.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: person.person_id,
                fileID: response.data.fileID,
                fileName: fileName + ".pdf",
                //lehrgang: this.kurzveranstaltungid,
                veranstaltung: this.kurzveranstaltungid,
                dokumentKategorie: "18DF48DEB26", // Zertifikat
              },
            }
          ).then(() => {
            error = false;
          });
        })
        .catch(() => {
          error = true;
        });

      //Arrays müssen so befüllt werden, da ansonsten die reactivity nicht greift
      if (error) {
        this.assignCertificateError.push(person.person_name);
      } else {
        this.assignCertificateSuccess.push(person.person_name);
      }
    },
  },
};
</script>
