<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ $t("global.unsavedchangestitle") }}</span>
          <modal-close-button @confirmed="stay" />
        </div>
        <div class="modal-body">
          <p>{{ message || $t("global.unsavedchanges") }}</p>
          <slot></slot>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-danger mr-3"
                data-dismiss="modal"
                @click="discard"
                :title="$t('global.discardandmoveon')"
              >
                <span class="font-khand font-weight-bold">{{
                  $t("global.discard")
                }}</span>
              </button>
              <button
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                @click="stay"
                :title="$t('global.closemodalandeditfurther')"
              >
                <span class="font-khand font-weight-bold">{{
                  $t("global.editfurther")
                }}</span>
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="save"
                :title="$t('global.save')"
                :disabled="!saveFunction"
                v-tooltip.hover="!saveFunction ? text=$t('global.invalidinputs') : text=''"
              >
                <span class="font-khand font-weight-bold">{{
                  $t("global.save")
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "UnsavedChangesModal",
  components: {
    ModalCloseButton,
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      default: "unsaved-changes-modal",
    },
    message: {
      type: String,
    },
    saveFunction: {
      type: Function,
    },
  },
  methods: {
    save() {
      if (this.saveFunction) {
        this.saveFunction();
      }
      this.closeModal();
    },
    discard() {
      this.$emit("discard");
      this.closeModal();
    },
    stay() {
      this.$emit("stay");
      this.closeModal();
    },
  },
};
</script>

<style scoped></style>
