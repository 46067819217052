<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kuerzel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungsstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vsl) => vsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusofclassesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.kurzveranstaltungstyp"
                        :options="kurzveranstaltungstypen"
                        :reduce="(vsl) => vsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.shorteventtype')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusofclassesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.shorteventtype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      :person="filters.verantwortlich"
                      :label="$t('global.Responsible')"
                      :allowOpen="false"
                      :editable="true"
                      @confirmed="setFilterVerantwortlich"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4 row">
                    <router-link
                      v-if="berechtigungen.m_kurzveranstaltung.create"
                      class="btn btn-success"
                      :to="{
                        name: 'template-kurzveranstaltung',
                        params: { anzeige: 0 },
                      }"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />
                      {{ $t("global.newtemplate") }}
                    </router-link>
                    <massenmutation-button
                      :propEntities="propEntities"
                      :selectedIds="this.selectedIds"
                      @mutated="refresh"
                    />
                  </div>

                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_kurzveranstaltung.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="(state) => infiniteHandler(state, 'app-api')"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="kurzveranstaltung-loeschen-modal"
      :selectedIds="selectedIds"
      @confirmed="kurzveranstaltungenLoeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.templateshortevent') + ' ' + $t('global.deleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import AppApi from "@/AppApi";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "template-kurzveranstaltungs-liste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    MassenmutationButton,
    FilterSearchResetButton,
    SingleSelectPerson,
  },
  mixins: [page, dashboard, filter],
  props: {},
  data() {
    return {
      verantwortliche: [],
      propEntities: [
        {
          tbl_name: "bxc_templatekurzveranstaltung",
          selectedIds: [],
        },
        {
          tbl_name: "bxc_portalinfo",
          selectedIds: [],
        },
      ],
      sortBy: "bezeichnung",
      sortDesc: false,
      navbarTitel: this.$t("global.templatesshortevents"),
    };
  },
  computed: {
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    kurzveranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.kurzveranstaltungstypen;
      },
    },
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "veranstaltungsnummer",
          sortable: true,
          label: this.$t("global.num"),
        },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "verantwortlich.personName",
          searchable: false,
          sortable: true,
          label: this.$t("global.Responsible"),
        },
        {
          key: "kurzveranstaltungstyp.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.shorteventtype"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.filters.bezeichnung;
      params.filterKurzbezeichnung = this.filters.kuerzel;
      params.status = this.setQueryParamList(this.filters.veranstaltungsstatus);
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlich?.id
      );
      params.kurzveranstaltungstyp = this.setQueryParamList(
        this.filters.kurzveranstaltungstyp
      );

      if (this.filters.count && this.filters.count != "")
        params.filterCount = this.filters.count;

      return params;
    },
  },
  created() {
    /* Die Abfrage lassen wir mal vorerst hier und nicht im Store.
       Wir müssen klären wie häufig ein Verantwortlicher ändern könnte */

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
    });

    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.initFilter(
      "template-kurzveranstaltungs-liste",
      "template/kurzveranstaltungsliste/",
      true
    );
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  watch: {
    selectedIds(newVal) {
      // set Portalinfo IDs for selected courses (used for massmutation)
      if (newVal.length == 0) {
        this.propEntities[1].selectedIds = [];
        return;
      }
      const selectedPortalinfoIds = [];
      newVal.forEach((id) => {
        const correspondingPortalinfoId = this.listData.find(
          (el) => el.id == id
        )?.portalinfo_id;
        if (correspondingPortalinfoId) {
          selectedPortalinfoIds.push(correspondingPortalinfoId);
        }
      });

      this.propEntities[1].selectedIds = selectedPortalinfoIds;
    },
  },
  methods: {
    setFilterVerantwortlich(person) {
      this.$set(this.filters, "verantwortlich", person);
    },
    refresh() {
      this.resetLoading(this);
    },
    details(kurzveranstaltung) {
      this.$router.push({
        name: "template-kurzveranstaltung",
        params: {
          kurzveranstaltungid: kurzveranstaltung.id,
          anzeige: 0,
          fromRoute: "template-kurzveranstaltungs-liste",
        },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit(
          "openLoeschenModal",
          "kurzveranstaltung-loeschen-modal"
        );
    },

    kurzveranstaltungenLoeschen() {
      AppApi.delete("template/kurzveranstaltungsliste/", {
        data: this.selectedIds,
      })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.coursesuccessfullydeleted"),
          });
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.coursedeletionfailed"),
          });
        })
        .finally(() => {
          this.$bus.$emit("searchWithFilter");
        });
    },

    jsonGetID(json) {
      if (json == null) return null;

      if (json instanceof String) return json;

      if (json.id != null) return json.id;

      return null;
    },
  },
};
</script>

<style></style>
