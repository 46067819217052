export const DOCS_BASE_URL = "https://www.bx-education.ch/docs/erp/";

export default {
  data() {
    return {
      noHelpURL: DOCS_BASE_URL,
      helpUrlMap: {
        Geschaeftspartner: DOCS_BASE_URL + "crm/#personen",
        "personen-liste": DOCS_BASE_URL + "crm/#personen",
        Firmenliste: DOCS_BASE_URL + "crm/#Unternehmen",
        Firma: DOCS_BASE_URL + "crm/#Unternehmen",
        Bildungsgang: DOCS_BASE_URL + "ausbildung/#Bildungsgang",
        Klasse: DOCS_BASE_URL + "ausbildung/#Klasse",
        Bildungsliste: DOCS_BASE_URL + "ausbildung/#Bildungsgang",
        Klassenliste: DOCS_BASE_URL + "ausbildung/#Klasse",
        Fach: DOCS_BASE_URL + "ausbildung/#Fächer",
        "faecher-liste": DOCS_BASE_URL + "ausbildung/#Fächer",
        Korrespondenzliste: DOCS_BASE_URL + "kommunikation/#Korrespondenz",
        Korrespondenz: DOCS_BASE_URL + "kommunikation/#Korrespondenz",
        Mailliste: DOCS_BASE_URL + "kommunikation/#Email",
        Mail: DOCS_BASE_URL + "kommunikation/#Email",
        Dokumentenliste: DOCS_BASE_URL + "kommunikation/#Dokumente",
        Dokument: DOCS_BASE_URL + "kommunikation/#Dokumente",
        NotizListe: DOCS_BASE_URL + "kommunikation/#Notizen",
        Notiz: DOCS_BASE_URL + "kommunikation/#Notizen",

        event: DOCS_BASE_URL + "ausbildung",
        Kursliste: DOCS_BASE_URL + "ausbildung",
        kurs: DOCS_BASE_URL + "ausbildung",

        Rechnungsliste: DOCS_BASE_URL + "finanzen/#Aufträge",
        Rechnung: DOCS_BASE_URL + "finanzen/#Aufträge",
        Honorarliste: DOCS_BASE_URL + "finanzen/#Honorare",
        Honorar: DOCS_BASE_URL + "finanzen/#Honorare",
        Debitoren: DOCS_BASE_URL + "finanzen/#Debitoren",
        Debitor: DOCS_BASE_URL + "finanzen/#Debitoren",
        Mahnungen: DOCS_BASE_URL + "finanzen/#Mahnungen",
        Mahnung: DOCS_BASE_URL + "finanzen/#Mahnungen",
        Zahlungen: DOCS_BASE_URL + "finanzen/#Zahlungseingänge",
        Zahlungseingang: DOCS_BASE_URL + "finanzen/#Zahlungseingänge",
        Belege: DOCS_BASE_URL + "finanzen/#Belege",
        Beleg: DOCS_BASE_URL + "finanzen/#Belege",

        anwesenheitsliste: DOCS_BASE_URL + "dozierende/#Anwesenheit",
        Anwesenheit: DOCS_BASE_URL + "dozierende/#Anwesenheit",
        Praesenzliste: DOCS_BASE_URL + "dozierende/#Präsenzen",
        Dozentenbuchungsliste: DOCS_BASE_URL + "dozierende/#Buchungen",
        Dozentenbuchung: DOCS_BASE_URL + "dozierende/#Buchungen",
        Dozenteneinsatzliste: DOCS_BASE_URL + "dozierende/#Einsatz",
        Dozenteneinsatz: DOCS_BASE_URL + "dozierende/#Einsatz",
        Noteneingabeliste: DOCS_BASE_URL + "dozierende/#Noteneingabe",
        Noteneingabe: DOCS_BASE_URL + "dozierende/#Noteneingabe",

        AnmeldungslisteSKJV: DOCS_BASE_URL + "anmeldungen/#Anmeldungen",
        Anmeldung: DOCS_BASE_URL + "anmeldungen/#Anmeldungen",
        Anmeldungsliste: DOCS_BASE_URL + "anmeldungen/#Abrechnung Anmeldungen",

        Leads: DOCS_BASE_URL + "leadmanagement/#Leads",
        Lead: DOCS_BASE_URL + "leadmanagement/#Leads",
        Aktivitaeten: DOCS_BASE_URL + "leadmanagement/#Aktivitäten",
        Kampagnen: DOCS_BASE_URL + "leadmanagement/#Kampagnen",
        Kampagne: DOCS_BASE_URL + "leadmanagement/#Kampagnen",

        Terminliste: DOCS_BASE_URL + "raeume-und-termine/#Termine",
        Termin: DOCS_BASE_URL + "raeume-und-termine/#Termine",
        TerminKalender: DOCS_BASE_URL + "raeume-und-termine/#Kalender",
        Bildschirmplaner: DOCS_BASE_URL + "raeume-und-termine/#Bildschirme",
        Screenevent: DOCS_BASE_URL + "raeume-und-termine/#Bildschirme",

        subventionsliste: DOCS_BASE_URL + "subventionen/#Subventionen",
        Subvention: DOCS_BASE_URL + "subventionen/#Subventionen",
        subventionsbeitragliste: DOCS_BASE_URL + "subventionen/#Subventionsbeiträge",
        Subventionsbeitrag: DOCS_BASE_URL + "subventionen/#Subventionsbeiträge",
        Subventionsabrechnungliste: DOCS_BASE_URL + "subventionen/#Abrechnungen",
        Subventionsabrechnung: DOCS_BASE_URL + "subventionen/#Abrechnungen",

        TorListe: DOCS_BASE_URL + "tor/#ToR",
        TorAbschlussSimulieren: DOCS_BASE_URL + "tor/#Abschlüsse simulieren",

        QuestionaireListe: DOCS_BASE_URL + "umfragen",
        Questionaire: DOCS_BASE_URL + "umfragen",

        StartseiteEinstellungen: DOCS_BASE_URL + "einstellungen",

        "template-bildungsgang-liste": DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateBildungsgang: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateKlassenliste: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateKlasse: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateFaecherliste: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateFach: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateKursliste: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",
        TemplateKurs: DOCS_BASE_URL + "einstellungen/#Vorlagen Bildungsgang",

        Raumliste: DOCS_BASE_URL + "einstellungen/#Räume und Termine",
        Raum: DOCS_BASE_URL + "einstellungen/#Räume und Termine",
        Gebäudeliste: DOCS_BASE_URL + "einstellungen/#Räume und Termine",
        Gebaude: DOCS_BASE_URL + "einstellungen/#Räume und Termine",

        Kompetenzrasterliste: DOCS_BASE_URL + "einstellungen/#Kompetenzraster",
        "Einstellungen-Kompetenzraster": DOCS_BASE_URL + "einstellungen/#Kompetenzraster",

        Notenliste: DOCS_BASE_URL + "einstellungen/#Noten",
        Note: DOCS_BASE_URL + "einstellungen/#Noten",

        Perioden: DOCS_BASE_URL + "einstellungen/#Ausbildung",
        Sperrtage: DOCS_BASE_URL + "einstellungen/#Sperrtage",

        MailVorlagenListe: DOCS_BASE_URL + "einstellungen/#Korrespondenz",
        MailTemplate: DOCS_BASE_URL + "einstellungen/#Korrespondenz",
        KorrespondenzVorlagenListe: DOCS_BASE_URL + "einstellungen/#Korrespondenzvorlage erstellen",
        KorrespondenzTemplate: DOCS_BASE_URL + "einstellungen/#Korrespondenzvorlage erstellen",

        Artikelliste: DOCS_BASE_URL + "einstellungen/#Finanzen",
        Artikel: DOCS_BASE_URL + "einstellungen/#Finanzen",

        TemplateQuestionaireListe: DOCS_BASE_URL + "einstellungen/#Vorlagen Umfragen",
        TemplateQuestionaire: DOCS_BASE_URL + "einstellungen/#Vorlagen Umfragen",

        EinstellungenAuswahlwerte: DOCS_BASE_URL + "einstellungen/#Auswahlwerte",
      },
    };
  },
};
