<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_anmeldungen.delete && produktid"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('DE')"
              :style="
                stammdatenSprache == 'DE'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0"
                >{{ $t("global.masterdata") }} - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('EN')"
              :style="
                stammdatenSprache == 'EN'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('FR')"
              :style="
                stammdatenSprache == 'FR'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produkt.bezeichnungDE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produkt.bezeichnungEN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produkt.bezeichnungFR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung/Titel</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->

          <!-- START Verlauf -->
          <div v-if="produktid">
            <container-headline
              :headline="$t('global.process')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <!--
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
                    <div class="row">
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.anmeldungsdatum"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.anmeldungsdatum = val)"
                          />
                          <label>{{ $t("global.registration") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.anmeldungsdatum_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.abgemeldet"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.abgemeldet = val)"
                          />
                          <label>{{ $t("global.canceld") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.abgemeldet_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.warteliste"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.warteliste = val)"
                          />
                          <label>{{ $t("global.waitinglist") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.warteliste_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.gebucht"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.gebucht = val)"
                          />
                          <label>{{ $t("global.booked") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.gebucht_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.abgelehnt"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.abgelehnt = val)"
                          />
                          <label>{{ $t("global.rejected") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.abgelehnt_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.besucht"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.besucht = val)"
                          />
                          <label>{{ $t("global.visited") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.besucht_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.zurueckgestellt"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.zurueckgestellt = val)"
                          />
                          <label>{{ $t("global.deferred") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.zurueckgestellt_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="anmeldung.noteneingabe"
                            :disabled="!editable"
                            @update="(val) => (anmeldung.noteneingabe = val)"
                          />
                          <label>{{ $t("global.scoreinput") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <div class="form-group">
                          <input
                            v-model.trim="anmeldung.noteneingabe_uhrzeit"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>{{ $t("global.Time") }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            --></div>
          </div>
          <!-- ENDE Verlauf -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Finanzen und Rechnung -->
          <container-headline
            :headline="$t('global.financesandinvoice')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <!--
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="anmeldung.verrechnung"
                          multiple
                          label="bezeichnung"
                          :options="verrechnungen"
                          :disabled="!editable"
                          :placeholder="$t('global.offsetting')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.offsetting") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="anmeldung.zahlungsplan"
                          label="bezeichnung"
                          :options="zahlungsplaene"
                          :disabled="!editable"
                          :placeholder="$t('global.paymentschedule')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.paymentschedule") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6"></div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model.trim="anmeldung.rechnungshinweis"
                          class="form-control"
                          placeholder=" "
                          :disabled="!editable"
                        ></textarea>
                        <label>{{ $t("global.invoicenotice") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model.trim="anmeldung.adresszusatz"
                          class="form-control"
                          placeholder=" "
                          :disabled="!editable"
                        ></textarea>
                        <label>{{ $t("global.addresssuffix") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            -->
          </div>
          <!-- ENDE Finanzen und Rechnung -->
          <!--START Rechnungsplit -->
          <container-headline
            :headline="$t('global.invoicesplit')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <!--
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-5">
                      <single-select-firma
                        id="firma-rechnungs-split"
                        :rechnungstraeger="anmeldung.person.id"
                        :firma="anmeldung.firma_rechnungssplit"
                        :key="'firmakey ' + firmakey"
                        :editable="editable"
                        :allowOpen="true"
                        :label="'2. ' + $t('global.invoicepartycompany')"
                        @confirmed="setFirmaRechnungssplit"
                      />
                    </div>
                    <div class="col-xl-5">
                      <single-select-person
                        id="person-rechnungs-split"
                        :person="anmeldung.person_rechnungssplit"
                        :firma="anmeldung.firma_rechnungssplit"
                        :label="'2. ' + $t('global.invoicepartyperson')"
                        :allowOpen="true"
                        :rollen="filterRollen"
                        :editable="editable"
                        @confirmed="setPersonRechungssplit"
                      />
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.number="anmeldung.anteil"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.shareinpercent") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select
                          v-model="anmeldung.adresse_rechnungssplit"
                          label="adresseKomplett"
                          :options="adressenVerbObjects"
                          :disabled="!editable"
                          :placeholder="$t('global.addressesinvoicesplit')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.addressesinvoicesplit") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <textarea
                          v-model.trim="anmeldung.rg_adresse_zusatz"
                          class="form-control"
                          placeholder=" "
                          rows="4"
                        />
                        <label>{{ $t("global.invoiceaddressaddition") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            -->
          </div>
          <!--ENDE Rechnungsplit -->
        </div>
        <!-- ENDE Rechte Seite -->
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="produkt-loeschen-modal" @confirmed="produktLoeschen" />

  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import server from "@/server";

import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import Navbar from "@/components/Navbar";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";

export default {
  name: "Produkt",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoeschenModal,
    UnsavedChangesModal,
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    produktid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editable: true,
      stammdatenSprache: "DE",
      styleHeadlineSelected: { filter: "none" },
      styleHeadline: { filter: "brightness(0.9)" },
      produkt: {
        id: "123",
        bezeichnungDE: "Produkt 1 DE",
        bezeichnungEN: "Product 1 EN",
        bezeichnungFR: "Product 1 FR",
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id || this.produktid) {
        return this.$t("global.product");
      } else {
        return this.$t("global.newproduct");
      }
    },
  },
  watch: {},

  created() {},

  mounted() {},

  methods: {
    setStammdatenSprache(sprache) {
      this.stammdatenSprache = sprache;
    },
    abbrechen() {
      this.$router.push({ name: "anmeldungen-liste" });
    },

    speichern() {},

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "produkt-loeschen-modal");
    },

    produktLoeschen() {},
  },
};
</script>

<style></style>
