<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <button
                      class="btn btn-success"
                      @click="oeffneBearbeitenModal"
                      v-if="berechtigungen.m_bildung.create"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_bildung.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="perioden"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="oeffneBearbeitenModal"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-bearbeiten" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeBearbeitenModal" />
            <h4>{{ $t("global.editperiod") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row p-0">
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    v-model="editPeriode.kuerzel"
                    class="form-control"
                    placeholder=" "
                    :readonly="!editable"
                  />
                  <label>{{ $t("global.shortdesignation") }}</label>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    v-model="editPeriode.bezeichnung"
                    class="form-control"
                    placeholder=" "
                    :readonly="!editable"
                  />
                  <label>{{ $t("global.designation") }}</label>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="form-group">
                  <v-select
                    v-model="editPeriode.semestertyp"
                    label="bezeichnung"
                    :options="semestertypen"
                    :placeholder="$t('global.semestertype')"
                    :disabled="!editable"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.semestertype") }}</label>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    v-model="editPeriode.perioden_gruppe"
                    class="form-control"
                    placeholder=" "
                    :readonly="!editable"
                  />
                  <label>{{ $t("global.group") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="editPeriode.startdatum"
                    @update="(val) => (editPeriode.startdatum = val)"
                    :disabled="!editable"
                  />
                  <label>{{ $t("global.start") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    date-format="dd.mm.yy"
                    :initial="editPeriode.enddatum"
                    @update="(val) => (editPeriode.enddatum = val)"
                    :disabled="!editable"
                  />
                  <label>{{ $t("global.end") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="closeBearbeitenModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              @click="periodeSpeichern"
              v-if="berechtigungen.m_bildung.update"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal @confirmed="periodeloeschen" :multiple="true" />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";

import DatePicker from "@/components/Datepicker";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "Perioden",
  components: {
    Navbar,
    HeadMenu,
    LoeschenModal,
    ModalCloseButton,
    DatePicker,
  },
  mixins: [page],
  props: {},
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 999,
      editPeriode: {},
      sortBy: "bezeichnung",
      sortDesc: true,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    navbarTitel() {
      return this.$t("global.settingperiods");
    },
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "semestertyp.bezeichnung",
          sortable: true,
          label: this.$t("global.semestertype"),
        },
        {
          key: "perioden_gruppe",
          sortable: true,
          label: this.$t("global.group"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        { key: "startdatum", sortable: true, label: this.$t("global.start") },
        { key: "enddatum", sortable: true, label: this.$t("global.end") },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    semestertypen: {
      get() {
        return this.$store.state.veranstaltung.semestertypen;
      },
    },
  },
  watch: {},
  mounted: function () {
    if (this.perioden.length == 0) this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    },
    initializePeriode() {
      this.editable = this.berechtigungen.m_bildung.update;
    },
    periodeSpeichern() {
      var json = this.editPeriode;
      json.entity = "periode";
      json.semestertyp = this.editPeriode.semestertyp.id;

      if (this.editPeriode.id) {
        Api.put("veranstaltungen/auswahlwerte/", json, {
          params: { id: this.editPeriode.id },
        })
          .then(() => {
            this.loadData();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.periodsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      } else {
        Api.post("veranstaltungen/auswahlwerte/", json)
          .then(() => {
            this.loadData();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.periodsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
      this.closeBearbeitenModal();
    },

    oeffneBearbeitenModal(periode) {
      if (periode) {
        this.editPeriode = Object.assign({}, periode);

        $("#modal-bearbeiten").modal({
          backdrop: "static",
          keyboard: false,
        });
      }
    },

    leereBearbeitenModal() {
      this.editPeriode = {};
    },

    closeBearbeitenModal() {
      this.leereBearbeitenModal();
      $("#modal-bearbeiten").modal("hide");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) this.$bus.$emit("openLoeschenModal");
    },

    periodeloeschen() {
      Api.delete("veranstaltungen/auswahlwerte/", {
        params: {
          entity: "periode",
          ids: this.selectedIds.join(","),
        },
      }).then(() => {
        this.loadData();
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style></style>
