<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              neuePosition
                ? $t("global.newclearingitem")
                : $t("global.editclearingitem")
            }}
          </h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      :headline="$t('global.masterdata')"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row">
                            <div class="col-xl-2">
                              <div class="form-group">
                                <input
                                  class="form-control"
                                  placeholder=" "
                                  v-model.number="
                                    verrechnungsposition.artikel.artikelnummer
                                  "
                                  readonly="true"
                                />
                                <label>{{ $t("global.itemnumber") }}</label>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <v-select
                                  v-model="verrechnungsposition.artikel"
                                  label="bezeichnung"
                                  :options="artikelListe"
                                  :get-option-label="
                                    (e) =>
                                      `${e.artikelnummer} / ${e.kurzbezeichnung} / ${e.bezeichnung}`
                                  "
                                  :placeholder="$t('global.item')"
                                  :clearable="false"
                                >
                                </v-select>
                                <label>{{ $t("global.item") }}</label>
                              </div>
                            </div>
                            <div class="col-xl-2">
                              <div class="form-group">
                                <b-form-input
                                  readonly
                                  v-model="
                                    verrechnungsposition.artikel.artikeltyp.bezeichnung
                                  "
                                />
                                <label>{{ $t("global.itemtype") }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-1">
                              <div class="form-group">
                                <input
                                  class="form-control"
                                  placeholder=" "
                                  v-model.number="verrechnungsposition.anzahl"
                                />
                                <label>{{ $t("global.Number") }}</label>
                              </div>
                            </div>

                            <div class="col-xl-3">
                              <div class="form-group">
                                <b-form-input
                                  readonly
                                  v-model="
                                    verrechnungsposition.artikel.einheit
                                      .bezeichnung
                                  "
                                />
                                <label>{{ $t("global.unit") }}</label>
                              </div>
                            </div>
                            <div class="col-xl-2">
                              <div class="form-group">
                                <ValidationProvider
                                  rules="required"
                                  immediate
                                  v-slot="{ passed }"
                                >
                                  <input
                                    class="form-control"
                                    placeholder=" "
                                    v-model.number="
                                      verrechnungsposition.manueller_preis
                                    "
                                    v-bind:class="{ 'border-danger': !passed }"
                                  />
                                  <label
                                    v-bind:class="{ 'text-danger': !passed }"
                                  >{{ $t("global.price") }}</label
                                  >
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="speichern"
              :disabled="invalid"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";
import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "VerrechnungspositionModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    ModalCloseButton
  },
  props: {
    position: {
      type: Object,
      default: null
    },
    veranstaltung: {
      type: String,
      required: false
    },
    lehrgang: {
      type: String,
      required: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    anmeldung: {
      type: String,
      required: false
    },
    isVeranstaltung: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      verrechnungsposition: {
        anzahl: 1,
        manueller_preis: 0,
        artikel: { artikeltyp: {}, einheit: {} }
      },
      artikelId: null,
      positionId: null
    };
  },
  computed: {
    neuePosition() {
      return this.position === null;
    },
    artikelListe() {
      return this.$store.state.artikel.artikel;
    },
  },
  watch: {
    position(val) {
      if (val) this.inititalizePosition(val);
      else this.clear();
    },
    artikelListe(val) {
      if (val.length > 0 && !this.verrechnungsposition.artikel.id)
        this.verrechnungsposition.artikel = val[0];
    },
    verrechnungsposition: {
      deep: true,
      handler(val) {
        if (val.artikel.id != this.artikelId) {
          this.artikelId = val.artikel.id;
          Api.get("artikel/preis/", {
            params: { artikel: this.artikelId }
          }).then((response) => {
            if (response.data.length != 0) {
              this.verrechnungsposition.manueller_preis =
                response.data[0].preis;
            } else {
              this.verrechnungsposition.manueller_preis = 0;
            }
          });
        }
      }
    }
  },
  async created() {
    await this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);
  },
  methods: {
    inititalizePosition(position) {
      if (position) {
        this.positionId = position.id;
        this.artikelId = position.artikel.id;
      }

      this.verrechnungsposition = position;
    },
    clear() {
      console.log("clear");

      this.verrechnungsposition = {
        anzahl: 1,
        manueller_preis: null,
        artikel: { artikeltyp: {}, einheit: {} }
      };
    },

    positionToJson() {
      return {
        anzahl: this.verrechnungsposition.anzahl,
        artikelId: this.artikelId
          ? this.artikelId
          : this.verrechnungsposition.artikel.id,
        id: this.positionId,
        ...(this.isVeranstaltung
          ? {...(this.isTemplate
            ? { templateVeranstaltungId: this.veranstaltung }
            : { veranstaltungId: this.veranstaltung })}
          : {...(this.isTemplate
            ? { templateLehrgangId: this.lehrgang }
            : { lehrgangId: this.lehrgang })}),
        anmeldungId: this.anmeldung,
        manuellerPreis: this.verrechnungsposition.manueller_preis,
        preis: this.verrechnungsposition.manueller_preis
      };
    },

    speichern() {
      if (this.positionId) {
        Api.put(
          "rechnungslegung/verrechnungsposition/",
          this.positionToJson(),
          {
            params: { id: this.positionId }
          }
        ).then(() => {
          this.closeModal();
        });
      } else {
        Api.post(
          "rechnungslegung/verrechnungsposition/",
          this.positionToJson()
        ).then(() => {
          this.closeModal();
        });
      }
    },

    closeModal() {
      this.shown = false;
      this.clear();
      this.$emit("position-changed");
      $("#verrechnungsposition-modal").modal("hide");
    }
  }
};
</script>

<style>
</style>
