<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.kurzbezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['adresse.strasse_nr']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.street") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['adresse.plz']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.zip") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['adresse.ort']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.city") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-success"
                      :to="{ name: 'Neues Gebäude' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="ml-auto mr-3">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_ressourcen.delete"
                    >
                      <font-awesome-icon icon="fa-duotone fa-trash" />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <div class="form-group p-0 m-0">
                          <input
                            class="form-control"
                            v-if="
                              field.key != 'selected' &&
                              field.searchable != false
                            "
                            size="sm"
                            type="text"
                            v-model="filters[field.key]"
                            :placeholder="field.label"
                            autocomplete="off"
                          />
                        </div>
                      </td>
                    </template>

                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal id="gebaeude-loeschen-modal" @confirmed="loeschen" />
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
//import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import InfiniteLoading from "vue-infinite-loading";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";

export default {
  name: "Gebäudeliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
  },
  mixins: [page, dashboard, filter],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      //gebaeudeliste: [],
      //filterCount: null,
      anzahlDaten: null,
      anzahlDatenFilter: null,
      infiniteId: +new Date(),
      sortBy: "bezeichnung",
      sortDesc: false,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kurzbezeichnung",
          sortable: true,

          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,

          label: this.$t("global.designation"),
        },
        {
          key: "adresse.strasse_nr",
          sortable: true,
          label: this.$t("global.street"),
        },
        { key: "adresse.plz", sortable: true, label: this.$t("global.zip") },
        { key: "adresse.ort", sortable: true, label: this.$t("global.city") },
      ];
    },
    queryParams: function () {
      var params = {};

      if (this.filters.filterCount != "")
        params.filterCount = this.setQueryParam(this.filters.filterCount);

      params.designation = this.setQueryParam(this.filters.bezeichnung);
      params.shortdesignation = this.setQueryParam(
        this.filters.kurzbezeichnung
      );
      params.streetNr = this.setQueryParam(this.filters["adresse.strasse_nr"]);
      params.zip = this.setQueryParam(this.filters["adresse.plz"]);
      params.city = this.setQueryParam(this.filters["adresse.ort"]);

      return params;
    },

    headline() {
      return this.$t("global.building");
    },
    navbarTitel() {
      return this.$t("global.building");
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    /* filterCount: function () {
		this.resetLoading(this);
	  }, */
  },
  created() {
    this.initFilter("gebaeude-liste", "gebaeude/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(gebaeudeliste) {
      this.$router.push({
        path: "/ressourcen/gebaeude/" + gebaeudeliste.id,
      });
    },
    /* resetLoading: debounce((self) => {
		self.page = 0;
		self.gebaeudeliste = [];
		self.infiniteId += 1;
	  }, 500), */

    loeschen() {
      Api.delete("gebaeude/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "gebaeude-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nobuildingselected"),
        });
      }
    },
    /* infiniteHandler($state) {
		Api.get("gebaeude/", {
		  params: this.queryParams,
		}).then((response) => {
		  //this.anzahlDaten = response.data[0].count;
		  //response.data.shift();
		  if (response.data.length === this.anzahlProPage) {
			this.page += 1;
			this.gebaeudeliste.push(...response.data);
			this.anzahlDatenFilter = this.gebaeudeliste.length;
			$state.loaded();
		  } else {
			this.gebaeudeliste.push(...response.data);
			this.anzahlDatenFilter = this.gebaeudeliste.length;
			$state.complete();
		  }
		});
	  },
	  selectAllRows(row) {
		if (row.selected) {
		  this.$refs.selectableTable.items.forEach((v) => {
			v.selected = true;
			if (!this.selectedIds.includes(v.id)) {
			  this.selectedIds.push(v.id);
			}
		  });
		} else {
		  this.$refs.selectableTable.items.forEach((v) => {
			v.selected = false;
		  });
		  this.selectedIds = [];
		}
		this.$forceUpdate();
	  },
	  onRowSelected(row) {
		if (row.item.selected) {
		  this.selectedIds.push(row.item.id);
		} else {
		  this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
		}
	  }, */
  },
};
</script>

<style></style>
