<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />
    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  class="ml-4"
                >
                  <button
                    v-if="berechtigungen.m_ressourcen.create"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    {{ $t("global.tolist") }}
                  </button>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE === 'DESKA'" class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success"
                    :disabled="invalid"
                    @click="speichernDeska"
                    @shortkey="speichernDeska"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary ml-2" @click="abbrechen">
                    {{ $t("global.tolist") }}
                  </button>
                  <!--<button
                    class="btn btn-danger ml-2"
                    @click="oeffneRMAModal"
                    v-if="isDESKAAdmin"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />
                    <span>Display Austauschen</span>
                  </button>-->
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_ressourcen.delete"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.trim="raum.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div
                    v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <input
                        v-model.trim="raum.kurzbezeichnung"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                  <div
                    v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <input
                        v-model.trim="raum.raumbeschriftung"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                      />
                      <label>{{ $t("global.roomlabel") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE === 'DESKA'" class="row">
                  <div class="col-xl-6">
                    <div
                      v-if="
                        raum.displayStatus == 'Label nicht verknüpft' ||
                        raum.displayStatus == null
                      "
                      class="form-group"
                    >
                      <input
                        v-model="raum.displayNr"
                        v-bind:class="
                          raum.displayStatus == 'Label nicht verknüpft'
                            ? 'border-danger'
                            : true
                        "
                        class="form-control"
                        :readonly="!isDESKAAdmin"
                        required
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          raum.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >{{ $t("global.displaynumber") }}</label
                      >
                    </div>
                    <div
                      v-if="raum.displayStatus == 'Label verknüpft'"
                      class="form-group"
                    >
                      <input
                        v-model="raum.displayNr"
                        v-bind:class="
                          raum.displayStatus == 'Label nicht verknüpft'
                            ? 'border-danger'
                            : true
                        "
                        class="form-control"
                        :readonly="true"
                        required
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          raum.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >{{ $t("global.displaynumber") }}</label
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model="raum.displayStatus"
                        class="form-control"
                        :readonly="true"
                        placeholder=" "
                      />
                      <label
                        v-bind:class="
                          raum.displayStatus == 'Label nicht verknüpft'
                            ? 'text-danger'
                            : true
                        "
                        >{{ $t("global.displaystatus") }}</label
                      >
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE === 'DESKA'" class="row">
                  <div class="col-xl-6">
                    <div class="form-checkbox">
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="raum.buchbar"
                      />
                      {{ $t("global.isbookable") }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  class="row"
                >
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="raum.bemerkung"
                        :readonly="!editable"
                        class="form-control"
                        placeholder=" "
                        rows="4"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Raum -->
          <div>
            <container-headline
              :headline="$t('global.room')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="raum.gebaeude"
                          label="bezeichnung"
                          :disabled="!editable"
                          :options="gebaeudeliste"
                          :reduce="(gl) => gl.id"
                          :placeholder="$t('global.building')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.building") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="raum.raumgruppe"
                          label="bezeichnung"
                          :disabled="!editable"
                          :options="raumgruppenliste"
                          :reduce="(rgl) => rgl.id"
                          :placeholder="$t('global.roomgroup')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.roomgroup") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <input
                          v-model.trim="raum.geschoss"
                          :readonly="!editable"
                          class="form-control"
                          placeholder=" "
                        />
                        <label>{{ $t("global.floor") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <input
                          type="number"
                          v-model.number="raum.flaeche"
                          :readonly="!editable"
                          class="form-control"
                          placeholder=" "
                        />
                        <label>{{ $t("global.areasqm") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <input
                          type="number"
                          v-model.number="raum.sitzplaetze"
                          :readonly="!editable"
                          class="form-control"
                          placeholder=" "
                        />
                        <label>{{ $t("global.seats") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Raum -->
        </div>
        <!-- ENDE Rechte Seite -->
        <div v-if="id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'RaumMitIdUndAnzeige',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.appointment") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'RaumMitIdUndAnzeige',
                      params: { id: id, anzeige: 1 },
                    }"
                    >{{ $t("global.blockingdate") }}</router-link
                  >
                </li>
                <li
                  v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                  class="nav-item"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'RaumMitIdUndAnzeige',
                      params: { id: id, anzeige: 2 },
                    }"
                    >{{ $t("global.workplace") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Terminliste -->
                  <terminliste
                    :shown="anzeige == 0 ? true : false"
                    :raum="id"
                  />
                  <!-- Ende Terminliste -->
                </div>
                <div v-show="anzeige === 1">
                  <!-- Start Sperrterminliste -->
                  <sperrterminliste
                    :shown="anzeige == 1 ? true : false"
                    :raum="id"
                  />
                  <!-- Ende Sperrterminliste -->
                </div>
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                  v-show="anzeige === 2"
                >
                  <!-- Start Sperrterminliste -->
                  <arbeitsplatzlistereiter
                    :shown="anzeige == 2 ? true : false"
                    :raum="id"
                  />
                  <!-- Ende Sperrterminliste -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loeschen-modal id="raum-loeschen-modal" @confirmed="loeschen" />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Terminliste from "@/components/Reiter/Terminliste";

import Sperrterminliste from "@/components/Reiter/Sperrterminliste";
import Arbeitsplatzlistereiter from "@/components/Reiter/Arbeitsplatzliste";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Raum",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
    Terminliste,
    //Arbeitsplatzliste,
    Sperrterminliste,
    LoeschenModal,
    Arbeitsplatzlistereiter,
    UnsavedChangesModal,
  },
  metaInfo() {
    const bezeichnung = this.raum.bezeichnung;
    return {
      titleTemplate: () => {
        if (!this.id) {
          return this.$t("global.newroom");
        }
        return `${this.$t("global.romm")} ${bezeichnung}`;
      },
    };
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    gebaeudeId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      raum: {
        bezeichnung: null,
        kurzbezeichnung: null,
        bemerkung: null,
        buchbar: null,
        gebaeude: null,
        raumgruppe: null,
        geschoss: null,
        flaeche: null,
        sitzplaetze: null,
        raumbeschriftung: null,
        displayStatus: null,
        displayNr: null,
      },
      isDESKAAdmin: false,
      gebaeudeliste: [],
      raumgruppenliste: [],
      loading: false,
      editable: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.raum) {
        return `${this.$t("global.room")} ${this.raum.bezeichnung}`;
      } else {
        return this.$t("global.newroom");
      }
    },
  },
  created() {
    if (this.id) {
      Api.get("raum/", { params: { id: this.id } }).then((response) => {
        this.initializeRaum(response.data);
      });
    }

    Api.get("gebaeude/").then(
      (response) => (this.gebaeudeliste = response.data)
    );
    Api.get("raumgruppe/").then(
      (response) => (this.raumgruppenliste = response.data)
    );
  },
  mounted: function () {
    this.isDESKAAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").DESKAADMIN
      ) == -1
        ? false
        : true;

    this.editable = this.berechtigungen.m_ressourcen.update;
  },
  watch: {
    gebaeudeId: {
      handler(val) {
        this.raum.gebaeude = val;
      },
      immediate: true,
    },
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "Raumliste" });
    },
    initializeRaum(raum) {
      if (raum) {
        this.raum = raum;
        // abfangen wenn gebaeude/raumgrupppe = null ist, raum sollte eigentlich nicht null sein
        //this.initializeChangewatcher(["raum"]);
      } else {
        console.error("Raum ist null");
      }

      /*
      if (raum) {
        this.raum = raum;
        this.bezeichnung = raum.bezeichnung;
        this.kurzbezeichnung = raum.kurzbezeichnung;
        this.bemerkung = raum.bemerkung;
        this.gebaeude = raum.gebaeude;
        this.raumgruppe = raum.raumgruppe;
        this.geschoss = raum.geschoss;
        this.flaeche = raum.flaeche;
        this.sitzplaetze = raum.sitzplaetze;

        // this.initChangeWatcher(this.termin); Muss noch auf Single Object umgebaut werden
      } else {
        console.error("raum ist null");
      }


      */
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.raum);

      if (!this.id) {
        Api.post("raum/", json)
          .then((response) => {
            this.initializeRaum(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.roomsavedsuccessfully"),
            });
            this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
            /*  //this.disableChangeWatcher();
            this.$router.replace({
              name: "Raumliste",
              params: { id: response.data.id },
            }); */
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("raum/", json, { params: { id: this.raum.id } })
          .then((response) => {
            this.initializeRaum(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.roomsavedsuccessfully"),
            });
            this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    speichernDeska() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.raum);

      if (!this.id) {
        Api.post("arbeitsplatz/", json, {
          params: { type: 2 },
        })
          .then((response) => {
            this.initializeRaum(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.roomsavedsuccessfully"),
            });
            this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
            this.$router.replace({
              name: "RaumMitId",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("arbeitsplatz/", json, {
          params: { id: this.raum.id, type: 2 },
        })
          .then((response) => {
            this.postSave(response);
            this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    postSave(response) {
      this.initializeRaum(response.data);

      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.roomsavedsuccessfully"),
      });

      this.$router.replace({
        name: "RaumMitId",
        params: { id: response.data.id },
      });
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "raum-loeschen-modal");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("raum/", {
        params: { id: this.raum.id },
      }).then((response) => {
        if (response.data.success.length !== 0) {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.roomdeletedsuccessfully"),
          });
          this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
          this.schliesseLoeschenModal();
          this.$router.push({ name: "Raumliste" });
        } else {
          this.$notify({
            type: "warn",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.roomdeletionunsuccessful"),
          });
        }
      });
    },
  },
};
</script>

<style></style>
