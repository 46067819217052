<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.templatesubject')"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <fach-stammdaten
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="true"
            :faecher="faecher"
          />

          <fach-lektionen
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
            :istModulfach="istModulfach"
          />
        </div>

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <fach-bildungsgang
            :v-if="fachid"
            :fach="fach"
            :modulID="fach.fach ? fach.fach.id : null"
            :faecher="faecher"
            :editable="editable"
            :isTemplate="true"
          />

          <modul-informationen
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="false"
          />

          <fach-teilnehmer
            :v-if="fachid"
            :fach="fach"
            :editable="editable"
            :isTemplate="true"
          />
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="fach-loeschen-modal" @confirmed="fachloeschen" />
    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import FachStammdaten from "@/components/Bildung/Fach/Stammdaten";
import FachLektionen from "@/components/Bildung/Fach/Lektionen";
import FachBildungsgang from "@/components/Bildung/Fach/Bildungsgang";
import FachTeilnehmer from "@/components/Bildung/Fach/Teilnehmer";
import ModulInformationen from "@/components/Bildung/Fach/Modulinformationen";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "TemplateFach",
  components: {
    HeadMenu,
    Navbar,
    LoadingOverlay,
    LoeschenModal,
    FachStammdaten,
    FachBildungsgang,
    FachLektionen,
    FachTeilnehmer,
    ModulInformationen,
    UnsavedChangesModal,
  },
  metaInfo() {},
  mixins: [page, ChangeWatcherNew],
  props: {
    fachid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editable: false,
      loading: false,
      fach: {
        lehrgang: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
        },
      },
      istModulfach: false,
    };
  },
  watch: {
    faecherModulID(val) {
      let modulID = val[0];
      let faecher = val[1];

      if (modulID && faecher) {
        let modul = faecher.find((md) => md.id == modulID);

        this.fach.modulgruppe = modul?.modulgruppe;
        this.fach.moduleinsatz = modul?.moduleinsatz;
        this.fach.modultyp = modul?.modultyp;
        this.fach.modulbezeichnung = modul?.bezeichnung;
        this.fach.leistungspunkte = modul?.cp;
        if (!this.fach.bezeichnung) {
          this.fach.bezeichnung = modul?.bezeichnung;
        }

        if (!this.fach.kuerzel) {
          this.fach.kuerzel = modul?.kuerzel;
        }
      }
    },
  },
  computed: {
    faecherModulID() {
      //Für Watch trigger auf zwei Variabeln
      return [this.fach?.fach?.id, this.faecher];
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    lehrmethoden: {
      get() {
        return this.$store.state.veranstaltung.lehrmethoden;
      },
    },
    niveaus: {
      get() {
        return this.$store.state.veranstaltung.niveau;
      },
    },
    faecher: {
      get() {
        return this.$store.state.veranstaltung.faecher;
      },
    },
  },
  created() {
    if (this.fachid) {
      Api.get("template/veranstaltung/", { params: { id: this.fachid } }).then(
        (response) => {
          this.initializeFach(response.data);
        }
      );
    } else this.editable = true;
  },
  mounted: function () {
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
  },
  methods: {
    abbrechen() {
      this.$router.push({ name: "template-faecher-liste" });
    },

    initializeFach(fach) {
      if (fach) {
        this.fach = fach;

        if (!this.fach.lehrgang)
          this.fach.lehrgang = {
            bezeichnung: this.$t("global.notlinked"),
            lehrgangstatus: { bezeichnung: this.$t("global.notlinked") },
          };

        if (!this.fach.lehrgang.lehrgangstatus) {
          // Datenfail in der Datenbank Lehrgang ohne Status
          this.fach.lehrgang.lehrgangstatus = {
            bezeichnung: this.$t("global.notlinked"),
          };
        }

        if (this.fach.lehrgang.bildungsgangtyp) {
          const MODULUNTERRICHT = "1821FEA3F48";
          this.istModulfach =
            this.fach.lehrgang.bildungsgangtyp.id == MODULUNTERRICHT;
        }

        if (this.berechtigungen.b_bildung_fach.update) {
          this.editable = this.$CONST("EVENTEDITABLESTATI").includes(
            this.fach.veranstaltungsstatus.id
          )
            ? true
            : false;
        } else this.editable = false;
      } else {
        console.error("Fach ist null");
      }

      //this.initializeChangewatcher(["fach"]);
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.fach);

      // Relationen auf ID reduzieren

      json.veranstaltungsstatus = this.fach.veranstaltungsstatus.id;
      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").MODULE;

      if (this.fach.lehrgang) json.lehrgang = this.fach.lehrgang.id;
      if (this.fach.lehrmethode) json.lehrmethode = this.fach.lehrmethode.id;

      Api.put("template/veranstaltung/", json, { params: { id: this.fach.id } })
        .then((response) => {
          this.initializeFach(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.subjectsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "fach-loeschen-modal");
    },

    fachloeschen() {
      Api.delete("template/veranstaltung/", {
        params: { ids: this.fachid },
      }).then(() => {
        this.$router.push({ name: "template-faecher-liste" });
      });
    },
  },
};
</script>

<style></style>
