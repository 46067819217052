<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3 pt-0">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 pr-4 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <stammdaten :subvention="subvention" :editable="editable" />
      </div>

      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <container-headline
          :headline="$t('global.settlementdata')"
          :col="6"
        ></container-headline>

        <div class="row">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="abrechnung.abrechnungsnummer"
                      readonly
                      class="form-control"
                      :placeholder="$t('global.num')"
                    />
                    <label>{{ $t("global.num") }}</label>
                  </div>
                </div>
                <!-- TODO: Falls Subventionsabrechnung editierbar sein soll hier dropdown mit auswahlwerte der Subventionsbeitraege -->
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="abrechnung.subventionsbeitrag.bezeichnung"
                      readonly
                      class="form-control"
                      :placeholder="$t('global.subsidycontribution')"
                    />
                    <label>{{ $t("global.subsidycontribution") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <v-select
                      v-model="abrechnung.subventionsbeitrag.subventionskanton"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="subventionskantone"
                      :placeholder="$t('global.canton')"
                    >
                      <span slot="no-options"
                        >{{ $t("global.subsidycanton") }} -
                        {{ $t("global.taptosearch") }}</span
                      >
                    </v-select>
                    <label>{{ $t("global.subsidycanton") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="abrechnung.antrag"
                      placeholer=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (abrechnung.antrag = val)"
                    />
                    <label>{{ $t("global.request") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="abrechnung.abrechnung"
                      placeholer=" "
                      :disabled="!editable"
                      :show-icon="true"
                      @update="(val) => (abrechnung.antrag = val)"
                    />
                    <label>{{ $t("global.settlement") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <v-select
                      v-model="abrechnung.periode"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="abrechnungsperioden"
                      :placeholder="$t('global.period')"
                    >
                    </v-select>
                    <label>{{ $t("global.period") }}</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <v-select
                      v-model="abrechnung.antragstatus"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="antragsstati"
                      :placeholder="$t('global.requeststatus')"
                    >
                    </v-select>
                    <label>{{ $t("global.requeststatus") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <v-select
                      v-model="abrechnung.abrechnungsstatus"
                      label="bezeichnung"
                      :disabled="!editable"
                      :options="abrechnungsstati"
                      :placeholder="$t('global.settlementstatus')"
                    >
                    </v-select>
                    <label>{{ $t("global.settlementstatus") }}</label>
                  </div>
                </div>

                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="abrechnung.betrag"
                      readonly
                      class="form-control"
                      :placeholder="$t('global.amount')"
                    />
                    <label>{{ $t("global.amount") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import Stammdaten from "@/components/Subvention/Stammdaten.vue";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";
import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";

export default {
  name: "Subventionsabrechnung",
  components: {
    ContainerHeadline,
    DatePicker,
    HeadMenu,
    Navbar,
    Stammdaten,
  },
  mixins: [page],
  props: {
    subventionid: {
      type: String,
      required: false,
    },
    subventionsabrechnungid: {
      type: String,
      required: false,
    },
    /*
    editable: {
      type: Boolean,
      required: false,
    },
    */
  },
  data() {
    return {
      abrechnung: {
        subvention: {},
        subventionsbeitrag: { subventionskanton: {}, bezeichnung: {} },
        periode: {},
        antragstatus: {},
        abrechnungsstatus: {},
      },
      editable: false,
      subvention: {
        person: {},
        subventionskanton: {},
        subventionsstatus: {},
        ausbildungstatus: {},
        ausbildung: { abschluss: {}, ausbildungtyp: {}, ausbildungdauer: {} },
        subventionsbeitrag: {},
      },
    };
  },
  watch: {},
  computed: {
    navbarTitel: function () {
      return this.$t("global.subsidysettlement");
    },
    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsperioden;
      },
    },
    antragsstati: {
      get() {
        return this.$store.state.subventionbeitrag.antragsstati;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.subventionbeitrag.abrechnungsstati;
      },
    },
  },
  mounted() {
    this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
    this.$store.dispatch(
      `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
    );
    this.getAbrechnung();
  },
  created() {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "subventionsabrechnungliste" });
    },
    getAbrechnung() {
      Api.get("subvention/abrechnung/", {
        params: {
          id: this.subventionsabrechnungid,
        },
      }).then((response) => {
        this.abrechnung = response.data;
        this.subvention = response.data.subvention;
      });
    },
  },
};
</script>
