<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter :col="12" class="d-xl-flex" :headline="$t('global.filter')"></head-menu-filter>
            <div class="row mb-2">
              <div class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input v-model="filters.bezeichnung" class="form-control input" type="text" placeholder=" " />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input v-model.trim="filters.kuerzel" class="form-control input" type="text" placeholder=" " />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select v-model.trim="filters.lehrgangstatus" :options="lehrgangstati" :reduce="(lsl) => lsl.id"
                        label="bezeichnung" :placeholder="$t('global.statuseducationalcourse')" multiple>
                        <span slot="no-options">{{
                          $t("global.nostatuseducationcoursefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select v-model.trim="filters.bildungsgangType" :options="bildungsgangTypeList"
                        :reduce="(v) => v.id" label="bezeichnung" :placeholder="$t('global.educationcoursetype')"
                        multiple>
                      </v-select>
                      <label>{{ $t("global.educationcoursetype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker date-format="dd.mm.yy" placeholder=" "
                        :initial="filters['veranstaltung.startdatumVon']" :show-icon="true"
                        field="veranstaltung.startdatumVon" @updatefull="setFilterDate" />
                      <label>{{ $t("global.startdatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker date-format="dd.mm.yy" placeholder=" "
                        :initial="filters['veranstaltung.startdatumBis']" :show-icon="true"
                        field="veranstaltung.startdatumBis" @updatefull="setFilterDate" />
                      <label>{{ $t("global.startdateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select v-model.trim="filters.lehrgangsleiter" :options="verantwortliche" :reduce="(v) => v.id"
                        label="personName" :placeholder="$t('global.courseinstructor')" multiple>
                      </v-select>
                      <label>{{ $t("global.courseinstructor") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input v-model="filters.count" class="form-control input" type="text" placeholder=" " />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu :col="12" class="d-xl-flex" :headline="$t('global.educationcourses')" :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter" :gesamtanzahl="gesamtanzahl"></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4 row">
                    <router-link v-if="berechtigungen.b_bildung_bildungsgang.create" class="btn btn-success"
                      :to="{ name: 'bildungsgang', params: { anzeige: 0 } }">
                      <font-awesome-icon icon="fa-regular fa-plus" class="mr-2" />
                      {{ $t("global.new") }}
                    </router-link>
                    <massenmutation-button :propEntities="propEntities" :selectedIds="this.selectedIds"
                      @mutated="refresh" />
                  </div>

                  <div class="mr-4 float-right">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_bildung_bildungsgang.delete">
                      <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table 
                    ref="selectableTable" 
                    small 
                    tbody-tr-class="item" 
                    :items="listData" 
                    :fields="fields"
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc" 
                    sort-icon-left 
                    fixed 
                    selectable
                    select-mode="range" 
                    @row-dblclicked="details" 
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td class="no-pointer" v-for="field in fields" :key="field.key">
                        <b-input v-if="
                          field.key != 'selected' && field.searchable != false
                        " size="sm" v-model="filters[field.key]" :placeholder="field.label" />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox ref="headerCheckbox" v-model="header.selected"
                        @input="selectAllRows(header)"></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox v-model="row.item.selected" @input="onRowSelected(row)"></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal id="bildungsgang-loeschen-modal" :selectedIds="selectedIds" @confirmed="bildungsgangloeschen"
      :multiple="true" />

    <messagebox-count :headerText="$t('global.educationalcoursesdeleted')" id="count-delete-alert-msgbox" :ok="true"
      :cancel="false" :okText="$t('global.ok')" :cancelText="$t('global.cancel')" />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import App from "@/AppApi";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import DatePicker from "@/components/Datepicker.vue";

export default {
  name: "Bildungsliste",
  components: {
    DatePicker,
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    MassenmutationButton,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.educationcourses");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  data() {
    return {
      verantwortliche: [],
      propEntities: [
        {
          tbl_name: "bxc_lehrgang",
          selectedIds: [],
        },
        {
          tbl_name: "bxc_portalinfo",
          selectedIds: [],
        },
      ],
      sortBy: "bezeichnung",
      sortDesc: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "lehrgangnummer", label: this.$t("global.num") },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "lehrgangleiter.personName",
          searchable: false,
          sortable: true,
          label: this.$t("global.courseinstructor"),
        },
        {
          key: "lehrgangstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.statuseducationalcourse"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.filters.bezeichnung;
      params.filterKurzbezeichnung = this.filters.kuerzel;
      params.filterLehrgangstatus = this.setQueryParamList(
        this.filters.lehrgangstatus
      );
      params.verantwortlicher = this.setQueryParamList(
        this.filters.lehrgangsleiter
      );
      params.filterBildungsgangNr = this.filters.lehrgangnummer;
      params.filterBildungsgangType = this.setQueryParamList(
        this.filters.bildungsgangType
      );
      params.filterStartdatumVon = this.filters["veranstaltung.startdatumVon"];
      params.filterStartdatumBis = this.filters["veranstaltung.startdatumBis"];

      // TODO:
      //params.filterLSBez = this.filters['lehrgangstatus.bezeichnung']
      //params.filterVBez = this.filters['lehrgangleiter.personName']

      if (this.filters.count && this.filters.count != "")
        params.filterCount = this.filters.count;

      return params;
    },
    lehrgangstati: {
      get() {
        return this.$store.state.veranstaltung.lehrgangstati;
      },
    },
    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    bildungsgangTypeList: {
      get() {
        return this.$store.state.veranstaltung.bildungsgangtypen;
      },
    },
  },
  created() {
    /* Die Abfrage lassen wir mal vorerst hier und nicht im Store.
       Wir müssen klären wie häufig ein Verantwortlicher ändern könnte */

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
    });

    if (this.lehrgangstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.initFilter("bildungsgang-liste", "lehrgaenge/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  watch: {
    selectedIds(newVal) {
      // set Portalinfo IDs for selected courses (used for massmutation)
      if (newVal.length == 0) {
        this.propEntities[1].selectedIds = [];
        return;
      }
      const selectedPortalinfoIds = [];
      newVal.forEach((id) => {
        const correspondingPortalinfoId = this.listData.find(
          (el) => el.id == id
        )?.portalinfoId;
        if (correspondingPortalinfoId) {
          selectedPortalinfoIds.push(correspondingPortalinfoId);
        }
      });

      this.propEntities[1].selectedIds = selectedPortalinfoIds;
    },
  },
  methods: {
    refresh() {
      this.resetLoading(this);
    },
    details(bildungsgang) {
      this.$router.push({
        name: "bildungsgang",
        params: {
          lehrgangid: bildungsgang.id,
          anzeige: 0,
          fromRoute: "bildungsgang-liste",
        },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
    },

    bildungsgangloeschen() {
      this.delete("lehrgaenge/", this.selectedIds);
    },

    jsonGetID(json) {
      if (json == null) return null;

      if (json instanceof String) return json;

      if (json.id != null) return json.id;

      return null;
    },
  },
};
</script>

<style></style>
