<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="berechtigungen.m_korrespondenz.update"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    v-if="berechtigungen.m_korrespondenz.delete"
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mail-vorlage-stammdaten
          :navbarTitel="navbarTitel"
          :mail="mail"
          :editable="editable"
          :langs="langs"
          @translate="translate"
          :mailvorlagen="mailvorlagen"
        />
        <div class="col-xl-12 p-0 d-flex">
          <div
            v-for="(lang, index) in langs"
            :key="'headline-' + index"
            class="col-2 p-0 mr-1"
          >
            <div class="col clickable p-0" @click="setActiveLang(index)">
              <div
                class="block block-kopf bg-medium-blue"
                :class="activeLang === index ? '' : 'bg-grayscale-5 hover'"
              >
                <span class="col primary-headline-text p-0">
                  {{ $t("global.content") + " " + lang.kuerzel }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(lang, index) in langs"
          :key="'content-' + index"
          class="col-12 p-0"
        >
          <mail-vorlage-inhalt
            v-if="activeLang === index"
            :mail="mail"
            :editable="editable"
            :lang="lang"
          />
        </div>
      </div>
    </ValidationObserver>

    <loeschen-modal
      id="mail-vorlage-loeschen-modal"
      @confirmed="mailLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import store from "@/store";
import Comm from "@/Communication";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";
import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import MailVorlageStammdaten from "@/components/Kommunikation/MailVorlageStammdaten.vue";
import MailVorlageInhalt from "@/components/Kommunikation/MailVorlageInhalt.vue";

export default {
  name: "MailTemplate",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    MailVorlageStammdaten,
    MailVorlageInhalt,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeLang: 0,
      mail: {
        zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        translation: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
        absenderadresse: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
        antwortan: {
          de: { betreff: "", text: "" },
          en: { betreff: "", text: "" },
          fr: { betreff: "", text: "" },
          it: { betreff: "", text: "" },
        },
      },
      loading: false,
    };
  },
  computed: {
    langs: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.mailtemplates");
      } else {
        return this.$t("global.newmailtemplate");
      }
    },
    inhaltVorhanden() {
      let defaultSprache = this.langs.find((lang) => {
        return lang.id === this.mail.defaultsprache;
      });
      if (
        this.mail.translation[defaultSprache.kuerzel].text &&
        this.mail.translation[defaultSprache.kuerzel].betreff
      ) {
        return true;
      } else return false;
    },
    mailvorlagen: {
      get() {
        return this.$store.state.veranstaltung.mailvorlagen;
      },
    },
  },
  watch: {},

  created() {
    if (this.id)
      Comm.get("email-template/" + this.id).then((response) => {
        this.initializeMail(response.data);
      });
  },

  mounted() {
    this.editable = true;
    if (this.langs.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }
    if (this.mailvorlagen.length == 0) {
      this.$store
        .dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`)
        .then(() => {});
    }
  },

  methods: {
    abbrechen() {
      this.$router.push({ name: "mail-vorlagen-liste" });
    },

    initializeMail(mail) {
      if (mail) {
        this.mail = mail;

        if (this.mail.translation) {
          for (const [key, value] of Object.entries(this.mail.translation)) {
            this.$set(value, "text", this.highlightTextcodes(value.text));
          }
        }
      } else {
        console.error("mail vorlage ist null");
      }
      this.editable = this.berechtigungen.m_korrespondenz.update;
    },

    highlightTextcodes(text) {
      if (!text) return "";

      const pattern = /(@\[([^\]]+)\])|(\{([^}]+)\})/g;

      return text.replace(pattern, (match, p1, p2, p3, p4) => {
        const highlightedText = p1 || p3;
        return highlightedText
          ? `<span style="color:#5299e0;">${highlightedText}</span>`
          : match;
      });
    },

    unhighlightTextcodes(text) {
      if (!text) return "";

      const pattern =
        /<span style="color:#5299e0;">([^<]+)<\/span>|<span style="color:#8bc34a;">([^<]+)<\/span>/g;

      return text.replace(pattern, (match, p1, p2) => {
        if (p1) return p1;
        if (p2) return p2;
      });
    },

    mailLoeschen() {
      Comm.delete("email-template/" + this.id).then(() => {
        this.$router.push({ name: "mail-vorlagen-liste" });
        //this.schliesseLoeschenModal();  wirft error weil es keine function findet
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "mail-vorlage-loeschen-modal");
    },

    speichern() {
      if (this.loading) return;

      if (this.inhaltVorhanden) {
        this.loading = true;
        var json = Object.assign({}, this.mail);

        if (json.translation) {
          for (const [key, value] of Object.entries(json.translation)) {
            this.$set(value, "text", this.unhighlightTextcodes(value.text));
          }
        }

        if (!this.id) {
          Comm.post("email-template/", json)
            .then((response) => {
              this.id = response.data.id;
              this.initializeMail(response.data);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.mailtemplatesuccessfullysaved"),
              });

              this.$router.replace({
                name: "mail-vorlage",
                params: { id: response.data.id, anzeige: 0 },
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          Comm.put("email-template/" + this.mail.id, json)
            .then((response) => {
              this.initializeMail(response.data);
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                text: this.$t("global.mailtemplatesuccessfullysaved"),
              });
            })
            .catch((e) => {
              this.$notify(apiErrorToAlert(e));
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text: this.$t(
            "global.pleasefillincontentandsubjectforatleastonelanguage"
          ),
        });
      }
    },
    sendTestMail() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.mail);

      if (json.inhalt) {
        this.$set(json, "inhalt", this.unhighlightTextcodes(json.inhalt));
      }
      if (json.translation) {
        this.langs.forEach((lang) => {
          json.translation[lang.kuerzel].text = this.unhighlightTextcodes(
            json.translation[lang.kuerzel].text
          );
        });
      }

      Api.put("mail/send/", json, {
        params: { id: this.id, testmail: true },
      })
        .then((response) => {
          this.initializeMail(response.data);

          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.testmailssentsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setActiveLang(index) {
      this.activeLang = index;
    },
    translate(sourceLang, targetLangs) {
      this.loading = true;
      let payload = {
        sourceLanguage: sourceLang.kuerzel,
        targetLanguageList: targetLangs,
      };

      let sourceText = this.unhighlightTextcodes(
        this.mail.translation[sourceLang.kuerzel].text
      );

      payload.sourceTextList = [];
      payload.sourceTextList[0] = sourceText;
      payload.sourceTextList[1] =
        this.mail.translation[sourceLang.kuerzel].betreff;
      const json = Object.assign({}, payload);

      Comm.post("correspondence-template-deepl/", json)
        .then((response) => {
          let thislangs = this.langs.filter((lang) => {
            return targetLangs.includes(lang.kuerzel);
          });
          thislangs.forEach((lang) => {
            if (lang.kuerzel !== sourceLang.kuerzel) {
              this.setText(response.data[0].translation_list, lang);
              this.setSubject(response.data[1].translation_list, lang);
            }
          });
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.translationwassuccessful"),
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.translationwasunsuccessful"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setText(translationList, lang) {
      this.mail.translation[lang.kuerzel].text = translationList.find(
        (translation) => translation.translation_language == lang.kuerzel
      ).translation;

      this.mail.translation[lang.kuerzel].text = this.highlightTextcodes(
        this.mail.translation[lang.kuerzel].text
      );
    },
    setSubject(translationList, lang) {
      this.mail.translation[lang.kuerzel].betreff = translationList.find(
        (translation) => translation.translation_language == lang.kuerzel
      ).translation;
    },
  },
};
</script>

<style></style>
