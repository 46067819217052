<template>
  <div>
    <div class="row ml-1 d-flex">
      <div class="ml-4">
        <button
          class="btn btn-primary"
          @click="openShowCertificatesForSelectedModal"
          :disabled="selectedObjects.length === 0"
          :title="selectedObjects.length === 0 ? $t('global.selectminone') : ''"
        >
          <span>{{ $t("global.showcertificatesforselected") }}</span>
        </button>
      </div>
      <div class="ml-2">
        <button
          class="btn btn-primary"
          @click="openAssignCertificatesForSelectedModal"
          :disabled="selectedObjects.length === 0"
          :title="selectedObjects.length === 0 ? $t('global.selectminone') : ''"
        >
          <span>{{ $t("global.assigncertificatesforselected") }}</span>
        </button>
      </div>
    </div>
    <zertifikat-auswahl-modal
      :id="'zertifikat-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectcertificatetype')"
      :certificateTypes="veranstaltungsgruppen"
      @ok="showCertificate"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-fuer-selektierte-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectcertificatetype')"
      :certificateTypes="veranstaltungsgruppen"
      @ok="showCertificateForSelected"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectcertificatetype')"
      :certificateTypes="veranstaltungsgruppen"
      @ok="assignCertificate"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-fuer-selektierte-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectcertificatetype')"
      :certificateTypes="veranstaltungsgruppen"
      @ok="assignCertificateForSelected"
    />
    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    />
    <pdf-layer
      id="pdf-layer-multi"
      ref="pdfLayerMulti"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    />
  </div>
</template>

<script>
import ZertifikatAuswahlModal from "@/components/Modals/ZertifikatAuswahlModal";

import Api from "@/Api";
import PdfGeneratorApi from "@/PdfGeneratorApi";

import server from "@/server";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "Zertifikate",
  components: {
    ZertifikatAuswahlModal,
  },
  props: {
    kurzveranstaltungid: {
      type: String,
      default: null,
    },
    selectedObjects: {
      type: Array,
    },
  },
  data() {
    return {
      pdfPath: "",
      pdfFiles: [],
      assignCertificateError: [],
      assignCertificateSuccess: [],
      activePerson: null,
    };
  },
  computed: {
    veranstaltungsgruppen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsgruppen;
      },
    },
  },
  watch: {},
  created() {},
  mounted() {
    if (this.veranstaltungsgruppen.length == 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }
  },
  methods: {
    openShowCertificateModal(data) {
      this.activePerson = data.item.person_id;
      this.$bus.$emit("open-modal", "zertifikat-anzeigen-modal");
    },
    showCertificate(json) {
      const fileName =
        "Zertifikat_Weiterbildung_" +
        json.certificateType?.bezeichnung?.replaceAll(" ", "_");

      const certificateNumber = json.certificateType.pdf_identifier;

      const reportName =
        "zertifikat-weiterbildung-" +
        certificateNumber +
        "-" +
        json.language +
        "-pdf";

      if (fileName && reportName) {
        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${this.activePerson}&date=${json.date}`;
        this.$refs.pdfLayer.show();
      }
    },
    openAssignCertificateModal(data) {
      this.activePerson = data.item.person_id;
      this.$bus.$emit("open-modal", "zertifikat-zuweisen-modal");
    },
    assignCertificate(json) {
      this.loading = true;

      const fileName =
        "Zertifikat_Weiterbildung_" +
        json.certificateType?.bezeichnung?.replaceAll(" ", "_");

      const certificateNumber = json.certificateType?.pdf_identifier;

      const reportName =
        "zertifikat-weiterbildung-" +
        certificateNumber +
        "-" +
        json.language +
        "-pdf";

      const pdfPath = `/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${this.activePerson}&date=${json.date}`;

      PdfGeneratorApi.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: this.activePerson,
                fileID: response.data.fileID,
                fileName: fileName + ".pdf",
                //lehrgang: this.kurzveranstaltungid,
                veranstaltung: this.kurzveranstaltungid,
                dokumentKategorie: "18DF48DEB26", // Zertifikat
              },
            }
          ).then(() => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.assignpdfsuccess"),
            });
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.assignpdferror"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openShowCertificatesForSelectedModal() {
      this.$bus.$emit(
        "open-modal",
        "zertifikat-fuer-selektierte-anzeigen-modal"
      );
    },
    async showCertificateForSelected(json) {
      await this.selectedObjects.forEach((person, index) => {
        //Generiere das PDF für die ausgewählten Personen und füge es dem PDF-Layer hinzu.
        this.generatePDFforSelectedView(person, index, json);
      });
      //Zeige den PDF-Layer mit den hinzugefügten Files, sobald alle PDFs generiert wurden.
      this.$refs.pdfLayerMulti.show();
    },
    generatePDFforSelectedView(person, index, certificateData) {
      this.pdfFiles = [];
      let kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
        " ",
        "_"
      );
      let date = certificateData.date;
      let certificateNumber = certificateData.certificateType?.pdf_identifier;
      let language = certificateData.language;
      let pdfAnzeigename = person.full_name;

      //Setze den korrekten Reportnamen anhand den getroffenen Angaben aus dem Modal, dmait der entsprechende Report geholt werden kann
      const reportName =
        "zertifikat-weiterbildung-" +
        certificateNumber +
        "-" +
        language +
        "-pdf";

      //Setze den Zertifikatnamen anhand der gewählten Sprache im Modal
      let certificateName = "";
      switch (language) {
        case "FR":
          certificateName = "Certificat_formation_continue_";
          break;
        case "IT":
          certificateName = "Certificato_formazione_continua_";
          break;
        case "EN":
          certificateName = "Certificate_Further_training";
          break;
        default:
          certificateName = "Zertifikat_Weiterbildung_";
      }

      const fileName =
        certificateName +
        kursname +
        "_" +
        person.full_name.replaceAll(" ", "_");

      let pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${person.person_id}&date=${date}`;

      Api.get(pdfPath).then((response) => {
        let retValue = {
          index: index,
          url: server.url + response.data.filepath,
          withCredentials: true,
          count: this.selectedObjects.length,
          size: pdfAnzeigename, //Variable wurde im Backend 'size' benannt, wird aber für den Anzeigenamen im PDF-Viewer genutzt
        };

        this.pdfFiles.push(retValue);
      });
    },
    openAssignCertificatesForSelectedModal() {
      this.$bus.$emit(
        "open-modal",
        "zertifikat-fuer-selektierte-zuweisen-modal"
      );
      this.assignCertificateError = [];
      this.assignCertificateSuccess = [];
    },
    async assignCertificateForSelected(json) {
      this.loading = true;

      await this.selectedObjects.forEach((person) => {
        //Generiere das PDF für die ausgewählten Personen und verknüpfe es mit der Person.
        this.generatePDFforSelectedAssign(person, json);
      });

      this.loading = false;

      //Zeige Erfolgs- oder Fehlermeldungen
      if (this.assignCertificateSuccess.length > 0) {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text:
            this.$t("global.assigncertificatessuccess") +
            `: (${this.assignCertificateSuccess.length})`,
        });
      }
      if (this.assignCertificateError.length > 0) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          text:
            this.$t("global.assigncertificateserror") +
            `: ${this.assignCertificateError.toString()}`,
        });
      }
    },
    generatePDFforSelectedAssign(person, certificateData) {
      this.pdfFiles = [];
      let kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
        " ",
        "_"
      );
      let date = certificateData.date;
      let certificateNumber = certificateData.certificateType?.pdf_identifier;
      let language = certificateData.language;

      //Setze den korrekten Reportnamen anhand den getroffenen Angaben aus dem Modal, dmait der entsprechende Report geholt werden kann
      const reportName =
        "zertifikat-weiterbildung-" +
        certificateNumber +
        "-" +
        language +
        "-pdf";

      //Setze den Zertifikatnamen anhand der gewählten Sprache im Modal
      let certificateName = "";
      switch (language) {
        case "FR":
          certificateName = "Certificat_formation_continue_";
          break;
        case "IT":
          certificateName = "Certificato_formazione_continua_";
          break;
        case "EN":
          certificateName = "Certificate_Further_training";
          break;
        default:
          certificateName = "Zertifikat_Weiterbildung_";
      }

      const fileName =
        certificateName +
        kursname +
        "_" +
        person.full_name.replaceAll(" ", "_");

      const pdfPath = `/pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.kurzveranstaltungid}&ids=${person.person_id}&date=${date}`;

      let error = false;
      //Speichere das PDF als Dokument und verknüpfe es mit der entsprechenden Person.
      Api.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: person.person_id,
                fileID: response.data.fileID,
                fileName: fileName + ".pdf",
                //lehrgang: this.kurzveranstaltungid,
                veranstaltung: this.kurzveranstaltungid,
                dokumentKategorie: "18DF48DEB26", // Zertifikat
              },
            }
          ).then(() => {
            error = false;
          });
        })
        .catch(() => {
          error = true;
        });

      //Arrays müssen so befüllt werden, da ansonsten die reactivity nicht greift
      if (error) {
        this.assignCertificateError.push(person.full_name);
      } else {
        this.assignCertificateSuccess.push(person.full_name);
      }
    },
  },
};
</script>
