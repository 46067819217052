export const SET_RESSOURCEN_STATI = "setRessourcenStati";
export const SET_RESSOURCEN_BUCHUNGSTYPEN = "setRessourcenBuchungsTypen";
export const SET_RESSOURCEN_RAEUME = "setRaeume";
export const SET_RESSOURCEN_GEBAEUDE = "setGebaeude";
export const SET_RESSOURCEN_KONFLIKT_STATI = "setKonfliktStati";
export const SET_RESSOURCEN_SPERRTERMINE = "setSperrtermine";
export const SET_RESSOURCEN_PERIODIZITAET = "setPeriodizitaet";
export const SET_RESSOURCEN_STANDORTE = "setStandorte";
export const SET_RESSOURCEN_EIGENTUMSVERHAELTNISSE =
  "setEigentumsverhaeltnisse";
export const SET_RESSOURCEN_RAUMGRUPPEN = "setRaumgruppen";
