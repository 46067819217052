export default {
  data() {
    return {
      initialStates: {}, // Stores initial states of watched objects
      hasChanges: {}, // Tracks changes per object
      nextRoute: null, // Stores the route the user is attempting to navigate to
      watchedObjectNames: new Set(),
    };
  },
  watch: {},
  methods: {
    /**
     * Initializes the watcher for multiple objects.
     * @param {Array} objects - Array of object names (as strings) to watch.
     */
    initializeChangeWatcher(objects) {
      objects.forEach((objectName) => {
        if (this[objectName] !== undefined) {
          this.watchedObjectNames.add(objectName);
          // Deep clone the initial state
          this.initialStates[objectName] = JSON.parse(
            JSON.stringify(this[objectName])
          );
          this.$set(this.hasChanges, objectName, false);
        } else {
          console.warn(
            `Object "${objectName}" is not defined in the component's data.`
          );
        }
      });

      // Listen for browser/tab close
      // window.addEventListener("beforeunload", this.preventUnload);
    },

    /**
     * Normalizes values to treat null, undefined, and empty strings/arrays as equivalent.
     * @param {*} value - The value to normalize.
     * @returns {*} - Normalized value.
     */
    normalizeValue(value) {
      if (value === null || value === undefined) {
        return "";
      }
      if (Array.isArray(value) && value.length === 0) {
        return [];
      }
      return value;
    },

    /**
     * Deeply compares two objects with normalization.
     * @param {Object} obj1 - First object.
     * @param {Object} obj2 - Second object.
     * @returns {Boolean} - True if objects are equal, false otherwise.
     */
    areObjectsEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        const val1 = this.normalizeValue(obj1[key]);
        const val2 = this.normalizeValue(obj2[key]);

        const areBothObjects =
          typeof val1 === "object" && typeof val2 === "object";
        if (areBothObjects) {
          if (!this.areObjectsEqual(val1, val2)) return false;
        } else if (val1 !== val2) {
          return false;
        }
      }

      return true;
    },

    /**
     * Checks if a specific object has unsaved changes.
     * @param {String} objectName - The name of the object to check.
     * @param {Object} currentObject - The current state of the object.
     */
    checkForUnsavedChanges(objectName, currentObject) {
      const initialObject = this.initialStates[objectName];
      const isEqual = this.areObjectsEqual(currentObject, initialObject);
      this.$set(this.hasChanges, objectName, !isEqual);
    },

    /**
     * Handles the discard action from the modal.
     */
    handleDiscard() {
      // Reset all watched objects to their initial states
      Object.keys(this.initialStates).forEach((objectName) => {
        this[objectName] = JSON.parse(
          JSON.stringify(this.initialStates[objectName])
        );
        this.$set(this.hasChanges, objectName, false);
      });
      this.navigateToNextRoute();
    },

    /**
     * Handles the stay action from the modal.
     */
    handleStay() {
      this.nextRoute = null;
    },

    /**
     * Navigates to the stored next route after handling save/discard.
     */
    navigateToNextRoute() {
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
        this.nextRoute = null;
      }
    },

    /**
     * Checks if any of the watched objects have unsaved changes.
     * @returns {Boolean} - True if there are unsaved changes, false otherwise.
     */
    hasAnyChanges() {
      return Object.values(this.hasChanges).some((change) => change);
    },
  },

  beforeRouteLeave(to, from, next) {
    // Check if any changes are made
    Array.from(this.watchedObjectNames).forEach((objectName) => {
      this.checkForUnsavedChanges(objectName, this[objectName]);
    });

    if (this.hasAnyChanges()) {
      // Blockiere die Navigation und öffne das Modal
      this.$bus.$emit("open-modal", "unsaved-changes-modal");
      this.nextRoute = to;
    } else {
      next(); // Allow navigation if no changes
    }
  },
};
